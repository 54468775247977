import {
  Grid,
  Card,
  Select,
  TextInput,
  Button,
  NumberInput,
  Text,
  ScrollArea,
  ActionIcon,
  Group,
  Paper,
  Divider,
  Menu,
  Modal,
  NativeSelect,
  Pagination,
  Table,
  useMantineTheme,
  Badge,
} from "@mantine/core"; //For import mantine required functions and theme
import React, { useEffect, useState, useRef, forwardRef } from "react";
import {
  handleAddCustomer,
  handleAddInvoice,
  handleAddInvoice2,
  handleAddSaveInvoice,
  handleDeleteInvoiceTransaction,
  handleDeleteSaveInvoice,
  handleGetAllProductStock,
  handleGetCategory,
  handleGetCustomer,
  handleGetCustomerGroup,
  handleGetInvoice,
  handleGetOneInvoice,
  handleGetOneSaveInvoice,
  handleGetProduct,
  handleGetSaveInvoice,
  handleSaveInvoice,
  handleUpdateInvoice,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis
import { dataSlice, selectFilter } from "../../helpers/common";
import { useForm } from "@mantine/form"; // Mantine form import
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { Plus, Trash, X, Dots, Eye, Search } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import useStyles from "../../components/Styles/Style";
import notificationHelper from "../../helpers/notifications";
import { useNavigate, useParams } from "react-router-dom"; // For import the react dom for navigation components
import { dataSearch, Th } from "../../helpers/tableFunction";
import { useModals } from "@mantine/modals";
import "./invoice.css";

function EditExclusiveSales() {
  const targetRef = useRef(null);
  const { classes } = useStyles();
  const [cart, setCart] = useState([]);
  const [total_discount, setTotal_discount] = useState(0);
  const [amount_paid, setAmount_paid] = useState(0);
  const [balance, setBalance] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [saleTypeValue, setSaleTypeValue] = useState("Cash");
  const [saleTypeBank, setSaleTypeBank] = useState(null);
  const [detailsModal, setDetailModal] = useState(false);
  const [savedModal, setSavedModal] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(new Date());
  const [validateBarcode, setValidateBarcode] = useState(0);
  const [customer, setCustomer] = useState("");
  let navigate = useNavigate();
  const [variables, setVariables] = useState({
    addDrawer: false,
    data: [],
    skeletonLoading: false,
    submitLoading: false,
    productBarcode: false,
    invoiceList: [],
    productList: [],
    packetList: [],
    groupList: [],
    stockTypeList: [],
    taxList: [],
    customer: [],
    saleType: [],
    banksList: [],
    amountPaid: 0,
    invoiceDiscount: 0,
    stocks: [],
    packet_stock: [],
    invoiceDate: new Date(),
  });
  // Mantine custome style use
  const modals = useModals();
  const theme = useMantineTheme();
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [sortBy, setSortBy] = useState(null); // Seting the sortby table type
  const [reverseSortDirection, setReverseSortDirection] = useState(false); // For set the reverse sort direction
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [lastInvoice, setLastInvoice] = useState("");
  const [bulkstock, setBulkstock] = useState(0);
  const [packetstock, setPacketstock] = useState(0);
  const [bulkprice, setBulkprice] = useState(0);
  const [packetprice, setPacketprice] = useState(0);
  const [entryType, setEntrytype] = useState("Product Quantity");
  const [group, setGroup] = useState([]); // For table data
  const [loadingState, setLoadingState] = useState(false);
  const params = useParams();

  const form = useForm({
    initialValues: {
      value: "",
      customer: "",
      customer_po: "",
      invoice_type: "Inclusive Tax",
      invoice_number: "",
      invoice_date: new Date().toLocaleString() + "",
      payment_terms: "As per discussion",
      category: "",
      product: "",
      index_number: 0,
      gst: "",
      qty: 1,
      discount: 0,
      discount_amount: 0,
      mrp: 0,
      offer_rate: 0,
      free_quantity: 0,
      taxable_value: 0,
      gst_amount: 0,
      discount_type: "number",
      invoiceTotal: "",
    },
    validate: {
      qty: (value) => (value < 1 ? "Quantity should have minimum 1" : null),
    },
  });

  const formCustomer = useForm({
    initialValues: {
      name: "",
      phone_number: "",
      credit_limit: 0,
      gstin: "",
      door_no: "",
      street: "",
      locality: "",
      pincode: "",
      city: "",
      state: "",
    },
    validate: {
      name: (value) => (value.length < 1 ? "Customer name is required" : null),
      phone_number: (value) =>
        value.length < 10 ? "Phone name is required" : null,
    },
  });

  const AddCustomer = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddCustomer(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Customer added successfully",
      });
      form.reset();
      const listGroup = await selectFilter({
        data: response.data.data,
      });

      setVariables((variables) => {
        return {
          ...variables,
          customer: listGroup,
        };
      });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const formDetails = useForm({
    initialValues: {
      deliver_note: "",
      suppliers_ref: "",
      other_ref: "",
      buyer_order_no: "",
      dated: "",
      despatch_doc_no: "",
      delivery_note_date: "",
      despatched_through: "",
      destination: "",
      terms_of_delivery: "",
      remark: "",
    },
  });

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const response2 = await handleGetOneInvoice(params.value);
      // On Response setting the data to variable
      console.log(response2.data[0].invoice_date);
      if (response2.status === 200) {
        form.setFieldValue("value", response2.data[0].value);
        form.setFieldValue(
          "customer",
          response2.data[0].customer_id.toString()
        );
        form.setFieldValue("customer_po", response2.data[0].customer_po);
        form.setFieldValue("invoice_date", response2.data[0].invoice_date);
        form.setFieldValue("invoice_type", response2.data[0].invoice_type);
        form.setFieldValue("invoice_number", response2.data[0].invoice_number);
        var data_list = response2.data[0].invoice_transactions;
        var list = [];
        for (let i = 0; i < data_list.length; i++) {
          var pick = {
            value: data_list[i].value,
            category_id: data_list[i].product.category,
            product: data_list[i].product,
            index_number: data_list[i].index_no,
            hsn_code: data_list[i].hsn_code,
            mrp_display: data_list[i].mrp_display,
            price: data_list[i].price,
            gst: data_list[i].igst,
            gst_amount: data_list[i].igst_amount,
            quantity: Number(data_list[i].quantity),
            free_quantity: Number(data_list[i].free_quantity),
            discount_percentage: Number(
              data_list[i].discount_percentage
            ).toFixed(2),
            discount_amount: Number(data_list[i].discount_amount).toFixed(2),
            total_price: Number(data_list[i].total_amount).toFixed(2),
          };
          list.push(pick);
        }
        setCart(list);
        setVariables((variables) => {
          return {
            ...variables,
            data: response2.data[0],
            invoiceDiscount: response2.data[0].discount_amount,
          };
        });
      }

      const response3 = await handleGetCategory();
      // On Response setting the data to variable
      if (response3.status === 200) {
        const listGroup = await selectFilter({
          data: response3.data.data,
        });
        setVariables((variables) => {
          return {
            ...variables,
            groupList: listGroup,
          };
        });
      }

      const response4 = await handleGetProduct();
      // On Response setting the data to variable
      if (response4.status === 200) {
        setVariables((variables) => {
          return {
            ...variables,
            productList: response4.data.data,
            skeletonLoading: false,
          };
        });
      }

      const response1 = await handleGetCustomerGroup();

      if (response1.status === 200) {
        var data = response1.data.data;
        var clean = data.map((data) => ({
          value: data.value.toString(),
          label: data.label,
        }));
        console.log(clean);
        setGroup(clean);
      }

      const response11 = await handleGetCustomer();
      // On Response setting the data to variable
      if (response11.status === 200) {
        const listGroup = await selectFilter({
          data: response11.data.data,
        });

        setVariables((variables) => {
          return {
            ...variables,
            customer: listGroup,
          };
        });
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const rows = sortedData.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td>{row.invoice_number}</td>
      <td>
        {row.custumer_id !== null &&
        row.custumer_id !== "" &&
        typeof row.custumer_id !== "undefined"
          ? row.customer.label
          : "--"}
      </td>
      <td>
        {row.custumer_id !== null &&
        row.custumer_id !== "" &&
        typeof row.custumer_id !== "undefined"
          ? row.customer.gstin
          : "--"}
      </td>
      <td>{Number(row.final_amount).toFixed(2)}</td>
      <td>
        {new Date(row.createdAt).toLocaleString("en-US", {
          hour12: true,
        })}
      </td>
      <td>
        <Button
          color="green"
          size="xs"
          onClick={async () => {
            const reg = {
              value: row.value,
            };

            const response = await handleGetOneSaveInvoice(row.value);
            console.log(response.data.save_invoice_transactions);

            if (response.status === 200) {
              var data_list = response.data.save_invoice_transactions;
              var list = [];
              for (let i = 0; i < data_list.length; i++) {
                var pick = {
                  category_id: data_list[i].product.category,
                  product: data_list[i].product,
                  index_number: data_list[i].index_no,
                  hsn_code: data_list[i].hsn_code,
                  mrp_display: data_list[i].mrp_display,
                  price: data_list[i].price,
                  gst: data_list[i].igst,
                  gst_amount: data_list[i].igst_amount,
                  quantity: Number(data_list[i].quantity),
                  free_quantity: Number(data_list[i].free_quantity),
                  discount_percentage: Number(
                    data_list[i].discount_percentage
                  ).toFixed(2),
                  discount_amount: Number(data_list[i].discount_amount).toFixed(
                    2
                  ),
                  total_price: Number(data_list[i].total_amount).toFixed(2),
                };
                list.push(pick);
              }
              setCart(list);
              setSavedModal(false);
            }
          }}
        >
          Load
        </Button>
      </td>
      <td>
        <Button
          onClick={() => openConfirmModal(row.value)}
          color="red"
          size="xs"
        >
          Delete
        </Button>
      </td>
      {/* For action drop down edit and delete the data */}
    </tr>
  ));

  //For delete confirm modal show Delete
  const openConfirmModal = (e) => {
    setSavedModal(false);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Do you want to delete this sale value",
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => setSavedModal(true),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteSaveInvoice(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Saved Sale deleted successfully",
      });
      var filter = variables.data;
      filter = filter.filter((img) => img.value !== e);
      setVariables({
        ...variables,
        submitLoading: false,
        data: filter,
      });
      const datas = dataSlice({
        data: filter,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const DeleteProduct = async (e, i) => {
    const response = await handleDeleteInvoiceTransaction(i);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Invoice Product deleted successfully",
      });
      var check = cart.findIndex(
        (img) => Number(img.product.value) === Number(e)
      );
      var data = cart;
      data.splice(check, 1);
      setCart(data);
      setTableRefresh(new Date());
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  var mergeTwoLists = function (list1, list2) {
    var array = [];
    for (let i = 0; i < list1; i++) {
      array[i].push(list1[i], list2[i]);
    }
    return array;
  };

  const selRef1 = useRef();

  const SelectItem1 = forwardRef(({ image, label, sku_code, ...others }) => (
    <div ref={selRef1} {...others}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text size="sm">{label}</Text>
        <Text size="xs">{sku_code}</Text>
      </div>
    </div>
  ));

  // Add Product to cart function
  const AddProduct = (e) => {
    setVariables({ ...variables });
    var check = cart.find(
      (img) => Number(img.product.value) === Number(e.product)
    );

    if (check !== null && typeof check != "undefined") {
      var dataList = cart;
      var check = cart.findIndex((img) => img.product.value === e.product);
      var list = {
        product: variables.productList.find(
          (img) => Number(img.value) === Number(e.product)
        ),
        mrp_display: Number(e.mrp),
        quantity: Number(e.qty) + Number(dataList[check].quantity),
        free_quantity:
          Number(e.free_quantity) + Number(dataList[check].free_quantity),
        price: Number(e.offer_rate),
        total_price: (
          Number(e.taxable_value) + Number(dataList[check].total_price)
        ).toFixed(2),
        gst: Number(e.gst),
        gst_amount: (
          Number(e.gst_amount) + Number(dataList[check].gst_amount)
        ).toFixed(2),
        discount_percentage: Number(e.discount),
        discount_amount: Number(e.discount_amount).toFixed(2),
      };

      dataList[check] = list;
      setCart(dataList);
      setTableRefresh(new Date());
    } else {
      var list = {
        product: variables.productList.find(
          (img) => Number(img.value) === Number(e.product)
        ),
        mrp_display: Number(e.mrp),
        quantity: Number(e.qty),
        free_quantity: Number(e.free_quantity),
        price: Number(e.offer_rate),
        total_price: Number(e.taxable_value),
        gst: Number(e.gst),
        gst_amount: Number(e.gst_amount).toFixed(2),
        discount_percentage: Number(e.discount),
        discount_amount: Number(e.discount_amount).toFixed(2),
      };
      var data = cart;
      data.push(list);
      console.log(data);
      setCart(data);
      setTableRefresh(new Date());
    }
    form.reset();
    var datas = variables.data;
    console.log(datas);
    form.setFieldValue("value", datas.value);
    form.setFieldValue("customer", datas.customer_id.toString());
    form.setFieldValue("customer_po", datas.customer_po);
    form.setFieldValue("invoice_date", datas.invoice_date);
    form.setFieldValue("invoice_type", datas.invoice_type);
    form.setFieldValue("invoice_type", datas.invoice_type);
    form.setFieldValue("invoice_number", datas.invoice_number);

    if (cart.length > 20) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
    setVariables({ ...variables, productBarcode: false });
  };

  const formRef = useRef();
  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();
  const input6 = useRef();
  const input7 = useRef();
  const input8 = useRef();
  const input9 = useRef();

  // Add sale invoice data
  const AddSaleData = async () => {
    if (form.values.customer !== "") {
      var customerId = form.values.customer;
      var customerPo = form.values.customer_po;
      var invoice_id = form.values.value;
      var invoice_date = form.values.invoice_date;
      var invoice_number = form.values.invoice_number;
      if (form.values.customer !== null && form.values.customer !== "") {
        var data = variables.customer.find(
          (img) => Number(img.value) === Number(form.values.customer)
        );
        customerId = form.values.customer;
      }
      const reg = {
        subTotal:
          cart.reduce(function (sum, current) {
            return +Number(sum) + Number(current.total_price);
          }, 0) +
          cart.reduce(function (sum, current) {
            return +Number(sum) + Number(current.gst_amount);
          }, 0),
        totalTax: Number(
          cart.reduce(function (sum, current) {
            return +Number(sum) + Number(current.gst_amount);
          }, 0)
        ).toFixed(2),
        value: invoice_id,
        customer: customerId,
        customer_po: customerPo,
        payment_terms: form.values.payment_terms,
        invoice_type: form.values.invoice_type,
        invoice_date: invoice_date,
        invoice_number: invoice_number,
        grand_total:
          cart.reduce(function (sum, current) {
            return +Number(sum) + Number(current.total_price);
          }, 0) +
          cart.reduce(function (sum, current) {
            return +Number(sum) + Number(current.gst_amount);
          }, 0),
        list: cart,
        balance:
          cart.reduce(function (sum, current) {
            return +Number(sum) + Number(current.total_price);
          }, 0) +
          cart.reduce(function (sum, current) {
            return +Number(sum) + Number(current.gst_amount);
          }, 0) -
          (Number(variables.amountPaid) + Number(variables.invoiceDiscount)),
        paid: variables.amountPaid,
        invoiceDiscount: variables.invoiceDiscount,
        date: variables.invoiceDate,
      };

      const response = await handleUpdateInvoice(reg);
      if (response.status === 200) {
        // navigate("/print_invoice/" + response.data.data[0].value);
        notificationHelper({
          color: "green",
          title: "Success",
          message: "Invoice updated successfully",
        });
      } else {
        notificationHelper({
          color: "red",
          title: "Failed! Please enter correct details",
          message: response.data.message,
        });
      }
    } else {
      alert("Please select & customer invoice date correctly");
    }
  };

  return (
    <div>
      <Grid pb={0} gutter="xs" className="zc-hide-scroll">
        <Grid.Col
          xs={4}
          className="invoiceItemLeft"
          style={{ display: "flex" }}
        >
          <Card p={0} className="border">
            <div
              style={{
                display: "flex",
                padding: 0,
                margin: 0,
                justifyContent: "space-between",
                background: "#043c64",
                borderTopRightRadius: 3,
                borderTopLeftRadius: 3,
              }}
            >
              <Text color="white" size={14} m={5} mt={8} ml={15}>
                Edit Invoice
              </Text>
              <Badge mt={8} mr={15} color="yellow">
                GST Exclusive
              </Badge>
            </div>

            <div style={{ padding: 15 }}>
              {/* For add new customer or select customer */}

              <Grid grow gutter="xs">
                <Grid.Col span={12}>
                  {/* Select or add new customer for selection */}
                  <div
                    style={{
                      display: "flex",
                      marginTop: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ fontSize: 12 }}></Text>
                    <Button
                      color="zevcore"
                      onClick={() =>
                        setVariables({ ...variables, addDrawer: true })
                      }
                      style={{ fontSize: 10, padding: 2, height: 15 }}
                    >
                      Add <Plus size={10} />
                    </Button>
                  </div>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Select
                    variant="filled"
                    dropdownPosition="bottom"
                    searchable
                    clearable
                    required
                    withAsterisk
                    label="Select Customer"
                    placeholder="Select Customer"
                    data={variables.customer}
                    value={customer}
                    {...form.getInputProps("customer")}
                    mr={2}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    variant="filled"
                    label="Enter Customer PO"
                    placeholder="Enter Customer PO"
                    value={form.values.customer_po}
                    {...form.getInputProps("customer_po")}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  {/* Invoice date pick */}
                  <DatePickerInput
                    variant="filled"
                    value={variables.invoiceDate}
                    onChange={(e) =>
                      setVariables({
                        ...variables,
                        invoiceDate: e,
                      })
                    }
                    label="Invoice Date"
                    placeholder="Pick date"
                    required
                    {...form.getInputProps("customer_po")}
                  />
                </Grid.Col>
              </Grid>
              <Divider my="xs" label="Product Select" labelPosition="center" />
              {/* For adding the products to carts */}
              <form
                ref={formRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                onSubmit={form.onSubmit((values) => AddProduct(values))}
              >
                <Grid grow gutter="xs">
                  <Grid.Col span={12}>
                    {/* Select the product */}
                    <Select
                      ref={input1}
                      size="xs"
                      required
                      itemComponent={SelectItem1}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          input1.current.blur();
                          input2.current.focus();
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input1.current.blur();
                          input2.current.focus();
                        }
                      }}
                      variant="filled"
                      label="Select Product"
                      placeholder="Select Product"
                      data={variables.productList}
                      searchable
                      maxDropdownHeight={400}
                      nothingFound="Nobody here"
                      filter={(value, item) =>
                        item.label
                          .toLowerCase()
                          .includes(value.toLowerCase().trim()) ||
                        item.sku_code
                          .toLowerCase()
                          .includes(value.toLowerCase().trim())
                      }
                      onChange={(e) => {
                        var pp = variables.productList.find(
                          (img) => Number(img.value) === Number(e)
                        );
                        form.setFieldValue("mrp", Number(pp.price));
                        form.setFieldValue("gst", Number(pp.tax.label));
                        form.setFieldValue("product", e);
                      }}
                      value={form.values.product}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    {/* For enter the quantity */}
                    <NumberInput
                      ref={input2}
                      size="xs"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input2.current.blur();
                          input3.current.focus();
                        }
                      }}
                      variant="filled"
                      value={form.values.index_number}
                      label="Index Number"
                      placeholder="Index Number"
                      {...form.getInputProps("index_number")}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    {/* For enter the quantity */}
                    <NumberInput
                      ref={input3}
                      size="xs"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input3.current.blur();
                          input4.current.focus();
                        }
                      }}
                      variant="filled"
                      value={form.values.qty}
                      label={entryType}
                      placeholder="Product Quantity"
                      {...form.getInputProps("qty")}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    {/* Product discount whole number */}
                    <NumberInput
                      ref={input4}
                      size="xs"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          input4.current.blur();
                          input5.current.focus();
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input4.current.blur();
                          input5.current.focus();
                        }
                      }}
                      readOnly
                      variant="filled"
                      label="Free Quantity"
                      value={form.values.free_quantity}
                      {...form.getInputProps("free_quantity")}
                    ></NumberInput>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    {/* For add the product price */}
                    <NumberInput
                      ref={input5}
                      size="xs"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input5.current.blur();
                          input6.current.focus();
                        }
                      }}
                      variant="filled"
                      value={form.values.mrp}
                      label="MRP"
                      placeholder="MRP"
                      {...form.getInputProps("mrp")}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    {/* Product discount whole number */}
                    <NumberInput
                      ref={input6}
                      size="xs"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          input6.current.blur();
                          input4.current.focus();
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input6.current.blur();
                          input4.current.focus();
                        }
                      }}
                      precision={4}
                      variant="filled"
                      label="Discount Percentage (%)"
                      onChange={(e) => {
                        var total_discount = Number(
                          form.values.mrp * (e / 100)
                        );
                        var offer_price = Number(
                          form.values.mrp - total_discount
                        );
                        var total_amount = Number(
                          offer_price * form.values.qty
                        );
                        var tax_amount = Number(
                          (total_amount * form.values.gst) / 100 / 2
                        ).toFixed(2);
                        var total_tax = Number(tax_amount * 2);
                        form.setFieldValue("offer_rate", Number(offer_price));
                        form.setFieldValue(
                          "taxable_value",
                          Number(total_amount)
                        );
                        form.setFieldValue(
                          "discount_amount",
                          Number(total_discount)
                        );
                        form.setFieldValue("gst", Number(form.values.gst));
                        form.setFieldValue("gst_amount", Number(total_tax));
                        form.setFieldValue("discount", Number(e));
                      }}
                      placeholder="Discount Percentage (%)"
                      value={form.values.discount}
                    ></NumberInput>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    {/* Product discount whole number */}
                    <NumberInput
                      ref={input7}
                      size="xs"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          input7.current.blur();
                          input4.current.focus();
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input7.current.blur();
                          input4.current.focus();
                        }
                      }}
                      precision={2}
                      variant="filled"
                      label="Discount Amount"
                      onChange={(e) => {
                        var total_percent =
                          (Number(e) / Number(form.values.mrp)) * 100;
                        var offer_price = Number(form.values.mrp - e).toFixed(
                          2
                        );
                        var total_amount = Number(
                          offer_price * form.values.qty
                        ).toFixed(2);
                        var tax_amount = Number(
                          (total_amount * form.values.gst) / 100
                        );
                        form.setFieldValue("discount", Number(total_percent));
                        form.setFieldValue("offer_rate", Number(offer_price));
                        form.setFieldValue(
                          "taxable_value",
                          Number(total_amount)
                        );
                        form.setFieldValue("gst", Number(form.values.gst));
                        form.setFieldValue("gst_amount", Number(tax_amount));
                        form.setFieldValue(
                          "discount_amount",
                          Number(e).toFixed(2)
                        );
                      }}
                      placeholder="Discount Amount"
                      value={form.values.discount_amount}
                    ></NumberInput>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    {/* Enter the product discount price */}
                    <NumberInput
                      ref={input8}
                      size="xs"
                      disabled
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          input8.current.blur();
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input8.current.blur();
                        }
                      }}
                      precision={2}
                      variant="filled"
                      label="Offer Rate"
                      value={form.values.offer_rate}
                      {...form.getInputProps("offer_rate")}
                    />
                  </Grid.Col>

                  <Grid.Col span={6}>
                    {/* Product discount whole number */}
                    <NumberInput
                      ref={input9}
                      size="xs"
                      disabled
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          input9.current.blur();
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          input9.current.blur();
                        }
                      }}
                      precision={2}
                      variant="filled"
                      label="Taxable Value"
                      value={form.values.taxable_value}
                      {...form.getInputProps("taxable_value")}
                    ></NumberInput>
                  </Grid.Col>
                </Grid>
                <div style={{ display: "flex", marginTop: 15 }}>
                  <Button
                    ref={input8}
                    ml={5}
                    fullWidth
                    type="submit"
                    color="zevcore"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </Grid.Col>
        <Grid.Col xs={8}>
          <Card className="border" pt={2}>
            <div style={{ paddingBottom: 10, paddingTop: 10 }}>
              <Paper className="invoiceItemHeight" px={10}>
                {/* View all the product list cart */}
                <Table className="tableBorder" ref={targetRef}>
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Description / Item</th>
                      <th>MRP</th>
                      <th>Qty</th>
                      <th>Discount(%)</th>
                      <th>Discount Amount</th>
                      <th>Offer Rate</th>
                      <th>GST(%)</th>
                      <th>GST Amount</th>
                      <th>Taxable Value</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody key={tableRefresh}>
                    {cart.length > 0 ? (
                      <>
                        {cart.map((row, index) => (
                          <tr className="products">
                            <td>{index + 1}</td>
                            <td>
                              {row.product.sku_code} - {row.product.label}{" "}
                            </td>
                            <td>{row.mrp_display}</td>
                            <td>{row.quantity}</td>
                            <td>{Number(row.discount_percentage)}</td>
                            <td>
                              {Number(
                                row.discount_amount * row.quantity
                              ).toFixed(2)}
                            </td>
                            <td>{Number(row.price).toFixed(2)}</td>
                            <td>{row.gst}</td>
                            <td>{Number(row.gst_amount).toFixed(2)}</td>
                            <td>{Number(row.total_price).toFixed(2)}</td>
                            <td
                              onClick={() =>
                                DeleteProduct(row.product.value, row.value)
                              }
                            >
                              <Button
                                color="red"
                                size="xs"
                                style={{ fontSize: 8, padding: 2, height: 15 }}
                              >
                                Remove
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : null}
                  </tbody>
                </Table>
              </Paper>
            </div>
            <Divider my="xs" />
            <Grid gutter="xs">
              <Grid.Col span={9}>
                <Grid>
                  <Grid.Col span={3} pb={0} pt={1}>
                    <TextInput
                      size="xs"
                      ml={5}
                      variant="filled"
                      value={variables.invoiceDiscount}
                      onChange={(e) =>
                        setVariables({
                          ...variables,
                          invoiceDiscount: e.target.value,
                        })
                      }
                      label="Total Discount"
                      placeholder="Total Discount"
                    />
                  </Grid.Col>
                  <Grid.Col span={3} pb={0} pt={1}>
                    <TextInput
                      size="xs"
                      variant="filled"
                      value={variables.amountPaid}
                      label="Amount Paid"
                      onChange={(e) =>
                        setVariables({
                          ...variables,
                          amountPaid: e.target.value,
                        })
                      }
                      placeholder="Amount Paid"
                    />
                  </Grid.Col>
                  <Grid.Col span={3} pb={0} pt={1}>
                    <TextInput
                      size="xs"
                      ml={5}
                      variant="filled"
                      readOnly
                      value={(
                        cart.reduce(function (sum, current) {
                          return +Number(sum) + Number(current.total_price);
                        }, 0) +
                        cart.reduce(function (sum, current) {
                          return +Number(sum) + Number(current.gst_amount);
                        }, 0) -
                        (Number(variables.amountPaid) +
                          Number(variables.invoiceDiscount))
                      ).toFixed(2)}
                      label="Balance"
                      placeholder="Balance"
                    />
                  </Grid.Col>
                  <Grid.Col span={3} pt={1}>
                    <Select
                      required
                      size="xs"
                      variant="filled"
                      label="Mode of payment"
                      placeholder="Mode Type"
                      data={[
                        { value: "Cash", label: "Cash" },
                        { value: "UPI", label: "UPI" },
                        { value: "Credit Card", label: "Credit Card" },
                        { value: "Debit Card", label: "Debit Card" },
                      ]}
                      value={saleTypeValue}
                      onChange={(e) => {
                        setSaleTypeValue(e);
                      }}
                    />
                  </Grid.Col>
                </Grid>
                <div
                  style={{
                    marginTop: 32,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="zevcore"
                    type="submit"
                    disabled={cart.length == 0 ? true : false}
                    size="xs"
                    onClick={() => AddSaleData()}
                  >
                    Update Invoice
                  </Button>
                </div>
              </Grid.Col>

              <Grid.Col span={3} mt={4}>
                <table className="tableBorder">
                  <tbody>
                    <tr>
                      <td>Taxable Value</td>
                      <td>
                        ₹
                        {cart
                          .reduce(function (sum, current) {
                            return +Number(sum) + Number(current.total_price);
                          }, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Taxes</td>
                      <td>
                        ₹
                        {cart
                          .reduce(function (sum, current) {
                            return +Number(sum) + Number(current.gst_amount);
                          }, 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>
                        ₹
                        {(
                          cart.reduce(function (sum, current) {
                            return +Number(sum) + Number(current.gst_amount);
                          }, 0) +
                          cart.reduce(function (sum, current) {
                            return +Number(sum) + Number(current.total_price);
                          }, 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Discount Amount()</td>
                      <td>₹{Number(variables.invoiceDiscount).toFixed(2)}</td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: "#043c64",
                        fontWeight: "bold",
                        color: "#ffffff",
                      }}
                    >
                      <td style={{ paddingLeft: 3 }}>Grand Total</td>
                      <td>
                        ₹
                        {Math.round(
                          cart.reduce(function (sum, current) {
                            return +Number(sum) + Number(current.gst_amount);
                          }, 0) +
                            cart.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.total_price);
                            }, 0) -
                            Number(variables.invoiceDiscount)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid.Col>
            </Grid>
          </Card>
        </Grid.Col>
      </Grid>
      {/* Add Customer Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Customer"}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formCustomer.onSubmit((values) => AddCustomer(values))}
            >
              <Grid>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Customer Name"
                    withAsterisk
                    value={formCustomer.values.customer}
                    placeholder="Customer Name"
                    {...formCustomer.getInputProps("customer")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Customer Attn"
                    value={formCustomer.values.customer_attn}
                    placeholder="Customer Attn"
                    {...formCustomer.getInputProps("customer_attn")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <Select
                    label="Select Customer Group"
                    placeholder="Select Customer Group"
                    data={group}
                    searchable
                    required
                    clearable
                    {...formCustomer.getInputProps("group_id")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <NumberInput
                    label="Phone Number"
                    withAsterisk
                    required
                    min={10}
                    value={formCustomer.values.phone_number}
                    placeholder="Phone Number"
                    {...formCustomer.getInputProps("phone_number")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Email"
                    withAsterisk
                    required
                    value={formCustomer.values.email1}
                    placeholder="Email"
                    {...formCustomer.getInputProps("email1")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Email-2"
                    value={formCustomer.values.email2}
                    placeholder="Email-2"
                    {...formCustomer.getInputProps("email2")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="GSTIN Number"
                    value={formCustomer.values.gstin}
                    placeholder="GSTIN Number"
                    {...formCustomer.getInputProps("gstin")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="State"
                    withAsterisk
                    required
                    value={formCustomer.values.state}
                    placeholder="State"
                    {...formCustomer.getInputProps("state")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <TextInput
                    label="Address"
                    value={formCustomer.values.address}
                    placeholder="Address"
                    {...formCustomer.getInputProps("address")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    loading={loadingState}
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    color="zevcore"
                  >
                    Add Customer
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Customer Modal */}
      <Modal
        withCloseButton={false}
        padding={0}
        size={"60%"}
        opened={detailsModal}
        onClose={() => setDetailModal(false)}
      >
        <div>
          <div
            style={{
              display: "flex",
              padding: 0,
              margin: 0,
              justifyContent: "space-between",
              background: "#043c64",
              borderTopRightRadius: 3,
              borderTopLeftRadius: 3,
            }}
          >
            <Text color="#ffffff" m={5} mt={7} ml={15}>
              Additional details
            </Text>
            <ActionIcon
              onClick={() => setDetailModal(false)}
              m={5}
              sx={{
                "&[data-disabled]": { opacity: 1 },
                "&[data-loading]": { backgroundColor: "#ffffff" },
              }}
            >
              <X size={18} />
            </ActionIcon>
          </div>
          <div
            style={{
              padding: 15,
            }}
          >
            <Grid>
              <Grid.Col span={4}>
                <TextInput
                  variant="filled"
                  value={formDetails.values.deliver_note}
                  label="Delivery Note"
                  placeholder="Delivery Note"
                  {...formDetails.getInputProps("deliver_note")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextInput
                  variant="filled"
                  value={formDetails.values.deliver_note}
                  label="Delivery Note"
                  placeholder="Delivery Note"
                  {...formDetails.getInputProps("deliver_note")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                {" "}
                <TextInput
                  variant="filled"
                  value={formDetails.values.suppliers_ref}
                  label="Supplier's Ref"
                  placeholder="Supplier's Ref"
                  {...formDetails.getInputProps("suppliers_ref")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextInput
                  variant="filled"
                  value={formDetails.values.other_ref}
                  label="Other Ref"
                  placeholder="Other Ref"
                  {...formDetails.getInputProps("other_ref")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                {" "}
                <TextInput
                  variant="filled"
                  value={formDetails.values.buyer_order_no}
                  label="Buyer's Order No."
                  placeholder="Buyer's Order No."
                  {...formDetails.getInputProps("buyer_order_no")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextInput
                  variant="filled"
                  value={formDetails.values.dated}
                  label="Dated"
                  placeholder="dd/mm/yyyy"
                  {...formDetails.getInputProps("dated")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextInput
                  variant="filled"
                  value={formDetails.values.despatch_doc_no}
                  label="Despatch Document No."
                  placeholder="Despatch Document No."
                  {...formDetails.getInputProps("despatch_doc_no")}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                {" "}
                <TextInput
                  variant="filled"
                  value={formDetails.values.delivery_note_date}
                  label="Deliver Note Date"
                  placeholder="Deliver Note Date"
                  {...formDetails.getInputProps("delivery_note_date")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                {" "}
                <TextInput
                  variant="filled"
                  value={formDetails.values.despatched_through}
                  label="Despatched Through"
                  placeholder="Despatched Through"
                  {...formDetails.getInputProps("despatched_through")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                {" "}
                <TextInput
                  variant="filled"
                  value={formDetails.values.destination}
                  label="Destination"
                  placeholder="Destination"
                  {...formDetails.getInputProps("destination")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextInput
                  variant="filled"
                  value={formDetails.values.terms_of_delivery}
                  label="Terms Of Delivery"
                  placeholder="Terms Of Delivery"
                  {...formDetails.getInputProps("terms_of_delivery")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextInput
                  variant="filled"
                  value={formDetails.values.remark}
                  label="Remark"
                  placeholder="Remark"
                  {...formDetails.getInputProps("remark")}
                />
              </Grid.Col>
            </Grid>

            <Group position="right" mt="md" mb="lg">
              <Button
                type="submit"
                color="zevcore"
                onClick={() => setDetailModal(false)}
              >
                Submit
              </Button>
            </Group>
          </div>
        </div>
      </Modal>

      <Modal
        opened={savedModal}
        onClose={() => setSavedModal(false)}
        title={"Saved Invoices"}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <ScrollArea>
              {/* Table data view */}
              <Table
                horizontalSpacing="md"
                verticalSpacing="xs"
                className={classes.striped}
              >
                {/* Table header defines */}
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Invoice</th>
                    <th>Customer</th>
                    <th>Customer GSTIN</th>
                    <th>Grand Total</th>
                    <th>Created At</th>
                    <th>Load</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {/* Table body defines from rows function */}
                <tbody key={refreshTable}>
                  {rows.length > 0 ? (
                    rows
                  ) : (
                    <tr>
                      <td>
                        <Text align="center">Nothing found</Text>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </ScrollArea>
            {/* For display the pagination and no of per pages list */}
            {/* For number of rows display in table */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "15px",
              }}
            >
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditExclusiveSales;
