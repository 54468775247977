import React, { useCallback, useState, useEffect, useRef } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs.jsx"; // For breadcrumbs data import
import {
  Skeleton,
  Card,
  TextInput,
  Button,
  Grid,
  Paper,
  Avatar,
  Modal,
  useMantineTheme,
  Group,
  FileButton,
  Image,
} from "@mantine/core"; //For import mantine required functions and theme //
import { useForm } from "@mantine/form"; // Mantine form import
import {
  handleEditPrefix,
  handleGetPrefixOne,
  handleUploadPrefixImage,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../helpers/notifications"; // Import notification for this page
import { URL_CONSTANTS } from "../../constants/constants.js";
import useStyles from "../../components/Styles/Style.jsx"; // Import the mantine custome styles from the
import { useModals } from "@mantine/modals";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";
// RichtextEditor

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Prefix() {
  // Mantine custome style use
  const { classes } = useStyles();
  const modals = useModals();
  const theme = useMantineTheme();
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    deleteIndex: 0,
    loadingData: false,
    logo: "",
    imageDrawer: false,
  });

  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const formEdit = useForm({
    initialValues: {
      value: 1,
      account_name: "",
      phone_number: "",
      alternate_number: "",
      email: "",
      gstin: "",
      pan_number: "",
      cin_number: "",
      address: "",
      prefix_number: "",
      logo: "logo.png",
    },
    // validate: {
    //   account_name: (value) =>
    //     value.length < 1 ? "Account name is required" : null,
    // },
  });

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetPrefixOne();
        // On Response setting the data to variable
        if (response.status == 200) {
          var datas = response.data;
          formEdit.setFieldValue("value", datas.value);
          formEdit.setFieldValue("account_name", datas.label);
          formEdit.setFieldValue("phone_number", datas.phone_number);
          formEdit.setFieldValue("alternate_number", datas.alternate_number);
          formEdit.setFieldValue("email", datas.email);
          formEdit.setFieldValue("gstin", datas.gstin);
          formEdit.setFieldValue("pan_number", datas.pan_number);
          formEdit.setFieldValue("cin_number", datas.cin_number);
          formEdit.setFieldValue("address", datas.address);
          formEdit.setFieldValue("prefix_number", datas.prefix_number);
          SetProfileimage(datas.logo);
          setVariables({
            ...variables,
            submitLoading: false,
            skeletonLoading: false,
            logo: datas.logo,
            data: response.data,
          });
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Edit function for updating designation values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true, loadingData: true });
    const response = await handleEditPrefix(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Account updated successfully",
      });
      var datas = response.data.data[0];
      formEdit.setFieldValue("value", datas.value);
      formEdit.setFieldValue("account_name", datas.label);
      formEdit.setFieldValue("phone_number", datas.phone_number);
      formEdit.setFieldValue("alternate_number", datas.alternate_number);
      formEdit.setFieldValue("email", datas.email);
      formEdit.setFieldValue("gstin", datas.gstin);
      formEdit.setFieldValue("pan_number", datas.pan_number);
      formEdit.setFieldValue("cin_number", datas.cin_number);
      formEdit.setFieldValue("address", datas.address);
      formEdit.setFieldValue("prefix_number", datas.prefix_number);
      setVariables({
        ...variables,
        submitLoading: false,
        loadingData: false,
        logo: datas.logo,
        data: response.data.data,
      });
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, loadingData: false });
    }
  };

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data;
    setImageTitle(datas.label);
    SetProfileimage(datas.logo);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // upload admin image values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: id_value,
    };
    e = { ...e, ...req };
    const response = await handleUploadPrefixImage(e);
    // Check for response for actions
    console.log(response.data.data[0]);
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Invoice logo upload successfully",
      });
      SetProfileimage(response.data.data[0].logo);
      setVariables({
        ...variables,
        submitLoading: false,
        imageDrawer: false,
      });
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Invoice Settings"
          Title="Settings"
          titleLink="/manages"
        />
      </Skeleton>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card shadow="sm" p="lg" className="border">
          <form onSubmit={formEdit.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col xs={12}>
                <form
                  onSubmit={formImage.onSubmit((values) =>
                    handleUpload(values)
                  )}
                >
                  <Paper
                    radius="md"
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    {profileimage !== null &&
                    profileimage !== "" &&
                    typeof profileimage !== "undefined" ? (
                      <Image
                        caption={imageTitle}
                        width={200}
                        height={80}
                        mx="auto"
                        src={URL_CONSTANTS.LOGO_URL + profileimage}
                      />
                    ) : (
                      <Image
                        caption={imageTitle}
                        width={200}
                        height={80}
                        mx="auto"
                        src={URL_CONSTANTS.LOGO_URL + profileimage}
                      />
                    )}

                    <Button
                      loading={loadingState}
                      mt={10}
                      fullWidth
                      radius="md"
                      onClick={() => openUpload(1)}
                      size="sm"
                      variant="outline"
                      color="zevcore"
                    >
                      Upload Invoice Logo
                    </Button>
                  </Paper>
                </form>
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Account Name"
                  withAsterisk
                  required
                  value={formEdit.values.account_name}
                  placeholder="Account Name"
                  {...formEdit.getInputProps("account_name")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Phone Number"
                  withAsterisk
                  required
                  maxLength={10}
                  value={formEdit.values.phone_number}
                  placeholder="Phone Number"
                  {...formEdit.getInputProps("phone_number")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Alternate Number"
                  withAsterisk
                  required
                  maxLength={10}
                  value={formEdit.values.alternate_number}
                  placeholder="Alternate Number"
                  {...formEdit.getInputProps("alternate_number")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Email"
                  withAsterisk
                  required
                  value={formEdit.values.email}
                  placeholder="Email"
                  {...formEdit.getInputProps("email")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="GSTIN Number"
                  withAsterisk
                  required
                  value={formEdit.values.gstin}
                  placeholder="GSTIN Number"
                  {...formEdit.getInputProps("gstin")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="PAN Number"
                  withAsterisk
                  required
                  value={formEdit.values.pan_number}
                  placeholder="PAN Number"
                  {...formEdit.getInputProps("pan_number")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="CIN Number"
                  withAsterisk
                  required
                  value={formEdit.values.cin_number}
                  placeholder="CIN Number"
                  {...formEdit.getInputProps("cin_number")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Invoice Prefix Number"
                  withAsterisk
                  required
                  value={formEdit.values.prefix_number}
                  placeholder="Invoice Prefix Number"
                  {...formEdit.getInputProps("prefix_number")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <TextInput
                  label="Address"
                  withAsterisk
                  required
                  value={formEdit.values.address}
                  placeholder="Address"
                  {...formEdit.getInputProps("address")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Button
                  loading={variables.loadingData}
                  radius="md"
                  type="submit"
                  size="sm"
                  color="zevcore"
                >
                  Update Invoice Settings
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>

      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={imageTitle}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* <Text>Upload State Image</Text> */}
            </Grid.Col>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image cropper */}
              {/* For cropper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Image
                      caption={imageTitle}
                      width={200}
                      height={80}
                      mx="auto"
                      src={URL_CONSTANTS.LOGO_URL + profileimage}
                    />

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="sm"
              variant="outline"
              color="zevcore"
            >
              Update invoice logo
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}
    </div>
  );
}
export default Prefix;
