import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
import { Card, Grid, Center, Text } from "@mantine/core/cjs";
import {
  AdjustmentsAlt,
  ArrowAutofitWidth,
  BuildingBank,
  CircleCheck,
  DatabaseImport,
  Discount2,
  FileInvoice,
  Location,
  Pencil,
  Percentage,
  Printer,
  Refresh,
  Settings,
  TableExport,
  TriangleSquareCircle,
  UserExclamation,
  UserPlus,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import {
  Avatar,
  Container,
  createStyles,
  Box,
  Modal,
  Progress,
} from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme
import useStyles from "../../components/Styles/Style";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import { handleGetUserOne } from "../../helpers/apis"; // For connect to the electron backend from this helper apis
import packageJson from "../../../package.json";
import axios from "axios";

function Manage() {
  let navigate = useNavigate();
  const [data, setData] = useState("");
  const links = [
    { label: "Profile Setting", icon: UserExclamation, link: "/profile" },
    { label: "Banking", icon: TriangleSquareCircle, link: "/bank" },
    { label: "Invoice Settings", icon: AdjustmentsAlt, link: "/account" },
    { label: "Tax", icon: Percentage, link: "/tax" },
  ];

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const response = await handleGetUserOne();
      if (response.status == 200) {
        console.log(response.data);
        setData(response.data);
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handlePage = (e) => {
    navigate(e);
  };
  const { classes } = useStyles();
  const [statusText, setStatusText] = useState("");
  const [opened, setOpened] = useState(false);
  const [opened2, setOpened2] = useState(false);
  const [counter, setCounter] = useState(0);
  const [complete, setComplete] = useState(false);

  return (
    <div>
      <BreadCrumb Text="Setting" />
      <div
        justify="center"
        align="center"
        style={{ marginBottom: 15, marginTop: 40 }}
      ></div>
      <Container style={{ width: "55%", margin: "auto" }}>
        <Grid justify="center" align="center">
          {links.map((item, index) => (
            <Grid.Col key={item.label} span="auto">
              <div
                className={classes.SettingBox2}
                onClick={() => {
                  navigate(item.link);
                }}
              >
                <Pencil className={classes.editIcon2} size={15} />
                <Box>
                  <item.icon size={50} strokeWidth="0.9" />
                  <Text sx={{ fontSize: "12px" }}>{item.label}</Text>
                </Box>
              </div>
            </Grid.Col>
          ))}
        </Grid>
      </Container>
      <div style={{ position: "absolute", bottom: 10, right: 0 }}>
        <Text size="sm" color="dimmed" mt={2}>
          © {new Date().getFullYear()}. Zevcore Private Limited. All Rights
          Reserved. Version - {packageJson.version.toString()} &nbsp;
        </Text>
      </div>
    </div>
  );
}

export default Manage;
