/* 
Project name: Zevgold APOS
Author: Zevcore Private Limited
Description: Zevcore Private Limited Zevgold APOS style css file
Created Date: 31/03/2022
Version: 1.0
Required: React and mantine
*/

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import {
  Button,
  Card,
  Center,
  Container,
  Grid,
  List,
  Modal,
  ScrollArea,
  Skeleton,
  Text,
  TextInput,
  Textarea,
  useMantineTheme,
} from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme
import "./invoice.css";
import {
  handleGetOneInvoice,
  handleGetCustomer,
  handleGetPrefixOne,
  handleGetBankOne,
  handleAddNotes,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis
import { useLocalStorage } from "@mantine/hooks";
import LogoW from "../../assets/images/logo1.png";
import {
  ArrowBack,
  CreditCard,
  DiscountOff,
  File,
  ListCheck,
  Printer,
} from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { useModals } from "@mantine/modals";
import axios from "axios";
import BreadCrumbs from "../../components/Breadcrumbs/BreadCrumbs";

function PrintSales() {
  const converter = require("number-to-words");
  const theme = useMantineTheme();
  const [token, setToken] = useState(localStorage.getItem("token")); //get saved local storage data
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [imageURL, setImageURL] = useState("public/images/product/");
  const [URL, setURL] = useState(process.env.REACT_APP_BACKEND_URL);
  const [URLFILE, setURLFILE] = useState(process.env.REACT_APP_FILE);
  const [PROFILE, setPROFILE] = useState(process.env.REACT_APP_PROFILE_URL);
  const [bank, setBank] = useState("");
  const [address, setAddress] = useState("");
  let navigate = useNavigate();

  const [type, setType] = useLocalStorage({
    key: "printer-type",
    defaultValue: "3",
  });

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    skuDrawer: false,
    openEdit: false,
    imageDrawer: false,
    deleteIndex: 0,
  });

  const [data, setData] = useState("");
  const [note, setNote] = useState([]);
  const [payment, setPayment] = useState([]);
  const [five, setFive] = useState("");
  const [twelve, setTwelve] = useState("");
  const [fifteen, setFifteen] = useState("");
  const [notes, setNotes] = useState([]);
  const [customer, setCustomer] = useState("");
  const [company, setCompany] = useState("");
  const [total_taxes, setTotal_taxes] = useState(0);
  const [total_taxable_amount, setTotal_taxable_amount] = useState(0);
  const [index_display, setindex_display] = useState("block");
  const [discount_display, setdiscount_display] = useState("block");
  const [hideIndex, setHideIndex] = useState(true);
  const [paymentUpdate, setPaymentUpdate] = useState(false);
  const [display, setDisplay] = useState("Hide");
  const [display1, setDisplay1] = useState("Hide");
  const [noteUpdate, setNoteUpdate] = useState(false);
  const [hideDiscount, setHideDiscount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(0);
  const modals = useModals();

  const formCustomer = useForm({
    initialValues: {
      amount: "",
      paymentMethod: "",
      paymentStatus: "",
      amountPaid: "",
      note: "",
    },
  });

  const params = useParams();
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        const reg = {
          value: params.value,
        };
        //   For get all the group data list
        const response = await handleGetOneInvoice(params.value);
        console.log(response.data[2][0].total_taxable_value);
        // On Response setting the data to variable
        if (response.status === 200) {
          setData(response.data[0]);
        }

        var filter = response.data[0].invoice_transactions;
        var fives = filter.filter((x) => x.igst == "5");
        setFive(fives);
        var twelves = filter.filter((x) => x.igst == "12");
        setTwelve(twelves);
        var fifteeens = filter.filter((x) => x.igst == "18");
        setFifteen(fifteeens);
        setTotal_taxes(response.data[1][0].total_taxes);
        setTotal_taxable_amount(response.data[2][0].total_taxable_value);
        setNotes(response.data[0].invoice_notes);

        const response2 = await handleGetPrefixOne();
        if (response2.status === 200) {
          setCompany(response2.data);
        }

        const response6 = await handleGetCustomer();
        console.log(response6.data);
        // On Response setting the data to variable
        if (response6.status === 200) {
          if (
            response.data.customer_id != null &&
            response.data.customer_id != "" &&
            typeof response.data.customer_id != "undefined"
          ) {
            var datas = response6.data.data,
              datas = datas.find(
                (img) => img.label === response.data.customer_id
              );
            setCustomer(datas);
          }
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // For form validation
  const form = useForm({
    initialValues: {
      notes: "",
      invoice_id: params.value,
    },
    validate: {
      notes: (value) => (value.length < 1 ? "Note is required" : null),
    },
  });

  // Add notes data
  const handleNotes = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddNotes(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Notes added successfully",
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const price_in_words = (price) => {
    var sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
          ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      str = words.reverse().join("");
    } else if (parseInt(price) == 0) {
      str = "Zero";
    } else str = "";
    return str;
  };
  const price_in_words2 = (price) => {
    var split = price.toString().split(".");
    var nonDecimal = split[0];
    var decimal = split[1];

    return (
      `Rupees` +
      price_in_words(Number(nonDecimal)) +
      ` & 
` +
      price_in_words(Number(decimal)) +
      `paise only`
    );
  };

  // Table data arrange by using function and loop throw each data range to table body
  const rows = notes.map((row, index) => <List.Item>{row.label}</List.Item>);

  const index_hide_func = () => {
    if (index_display === "block") {
      setindex_display("none");
      setDisplay("Show");
    } else {
      setindex_display("block");
      setDisplay("Hide");
    }
  };

  const discount_hide_func = () => {
    if (discount_display === "block") {
      setdiscount_display("none");
      setDisplay1("Show");
    } else {
      setdiscount_display("block");
      setDisplay1("Hide");
    }
  };

  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <Grid>
          <Grid.Col span={6}>
            <div
              style={{
                textAlign: "left",
              }}
              className="noprint"
            >
              <Button
                type="submit"
                color="dark"
                variant="outline"
                onClick={() => navigate("/view_sales")}
              >
                <ArrowBack size={18} /> &nbsp; Back to sales
              </Button>
            </div>
          </Grid.Col>
          <Grid.Col span={6}>
            <div
              style={{
                textAlign: "right",
              }}
              className="noprint"
            >
              <Button
                type="submit"
                color="cyan"
                variant="outline"
                mr={10}
                onClick={() => index_hide_func()}
              >
                <ListCheck size={18} /> &nbsp; {display} Index
              </Button>

              <Button
                type="submit"
                color="pink"
                variant="outline"
                mr={10}
                onClick={() => discount_hide_func()}
              >
                <DiscountOff size={18} /> &nbsp; {display1} Discount
              </Button>
            </div>
          </Grid.Col>
        </Grid>
      </div>
      {/* Main start here */}
      {data != "" ? (
        // For view the pdf invoice page
        <div className="page">
          {/* For view tax-invoice id and date */}
          <div
            style={{
              borderBottom: "2px solid #A64D79",
            }}
          >
            <h2
              style={{
                fontFamily: "sans-serif",
                textAlign: "center",
                fontWeight: 200,
                fontSize: 30,
                paddingTop: 10,
              }}
            >
              TAX-INVOICE
            </h2>
          </div>
          <div
            className="print_border"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ width: "55%" }}>
              <Center>
                <img
                  src={LogoW}
                  alt=""
                  style={{ width: "300px", height: "auto" }}
                />
              </Center>
            </div>
            <div style={{ width: "45%" }}>
              <h2
                style={{
                  fontFamily: "Poppins",
                  textAlign: "left",
                  fontWeight: 700,
                  color: "#A64D79",
                  fontSize: "30px",
                  marginTop: 10,
                }}
              >
                Imprulife Venture Pvt Ltd
              </h2>
              <p
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  textAlign: "left",
                  paddingLeft: "1px",
                  fontFamily: "open sans",
                }}
              >
                Address : 3-2-36E10, 2nd floor, Niriksha Arcade, Old DC Office
                Road, Brahmagiri Circle Udupi <br /> 576101, Karnataka, India
                Phone Number-: +91-9880567755 / +91- 7795062950, <br />
                Email-: info@imprulife.com, PAN : AAECI5553R, <br /> GSTIN:
                29AAECI5553R1ZR, CIN: U74999KA2017PTC104433
              </p>
            </div>
          </div>
          <div
            className="print_border"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ width: "50%" }}>
              <Text
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "30px",
                }}
              >
                INVOICE #
              </Text>
            </div>
            <div style={{ width: "50%" }}>
              <Text
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "30px",
                  textAlign: "right",
                }}
              >
                {data.invoice_number}
              </Text>
            </div>
          </div>
          {/* For view the customer name and address */}
          <div
            className="print_border"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ width: "50%" }}>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Customer Name: {data.customer.label}
              </p>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Customer Address: {data.customer.address}
              </p>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Email: {data.customer.email} , {data.customer.email2}
              </p>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Tel: {data.customer.phone_number}
              </p>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                GSTIN: {data.customer.gstin}
              </p>
            </div>
            <div style={{ width: "50%", textAlign: "right" }}>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Date of Invoice:{" "}
                {new Date(data.invoice_date).toLocaleDateString("en-IN")}
              </p>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Customer PO(If any)#: {data.customer_po}
              </p>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Due Date:{" "}
                {new Date(data.invoice_date).toLocaleDateString("en-IN")}
              </p>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Payment Terms: {data.payment_terms}
              </p>
              <p
                style={{
                  lineHeight: "18px",
                  margin: 0,
                  fontSize: "12px",
                  fontFamily: "open sans",
                }}
              >
                Due Amount: {data.due_amount}
              </p>
            </div>
          </div>
          <div>
            <div>
              {/* Table view */}
              <table className="print_table">
                <thead>
                  <tr>
                    <th className="table_head" rowSpan={2}>
                      #
                    </th>
                    <th className="table_head" rowSpan={2}>
                      <span style={{ display: index_display }}>Index No</span>
                    </th>
                    <th
                      className="table_head"
                      style={{ textAlign: "left", marginLeft: 5 }}
                      rowSpan={2}
                    >
                      Item &amp; Description
                    </th>
                    <th className="table_head" rowSpan={2}>
                      HSN/ SAC
                    </th>
                    <th className="table_head" rowSpan={2}>
                      Qty(Pcs)
                    </th>
                    <th className="table_head" rowSpan={2}>
                      MRP
                    </th>
                    <th className="table_head" rowSpan={2}>
                      <span style={{ display: discount_display }}>
                        Discount(%)
                      </span>
                    </th>
                    <th className="table_head" rowSpan={2}>
                      Offer rate
                    </th>
                    <th
                      className="table_head"
                      style={{ textAlign: "center" }}
                      rowSpan={1}
                      colSpan={2}
                    >
                      CGST
                    </th>
                    <th
                      className="table_head"
                      style={{ textAlign: "center" }}
                      rowSpan={1}
                      colSpan={2}
                    >
                      SGST
                    </th>
                    <th className="table_head" rowSpan={2}>
                      Amount
                    </th>
                  </tr>
                  <tr>
                    <th
                      className="table_head"
                      style={{ textAlign: "center" }}
                      rowSpan={1}
                    >
                      %
                    </th>
                    <th
                      className="table_head"
                      style={{ textAlign: "center" }}
                      rowSpan={1}
                    >
                      Amt
                    </th>
                    <th
                      className="table_head"
                      style={{ textAlign: "center" }}
                      rowSpan={1}
                    >
                      %
                    </th>
                    <th
                      className="table_head"
                      style={{ textAlign: "center" }}
                      rowSpan={1}
                    >
                      Amt
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* For view saled product list in loop with quantity and tax amount */}
                  {data.invoice_transactions.map((row, index) => (
                    <tr key={index}>
                      <td className="table_body">{index + 1}</td>
                      {row.index_no === "" &&
                      row.index_no === null &&
                      typeof row.index_no === "undefined" ? (
                        <td className="table_body">
                          <span style={{ display: index_display }}>0</span>
                        </td>
                      ) : (
                        <td className="table_body">
                          <span style={{ display: index_display }}>
                            {row.index_no}
                          </span>
                        </td>
                      )}
                      <td className="table_body" style={{ textAlign: "left" }}>
                        {row.product.label}
                      </td>
                      <td className="table_body">{row.product.hsn_code}</td>
                      <td className="table_body">{row.quantity}</td>
                      <td className="table_body">{row.mrp_display}</td>
                      <td className="table_body">
                        <span style={{ display: discount_display }}>
                          {row.discount_percentage.toFixed(4)}
                        </span>
                      </td>
                      <td className="table_body">{row.price.toFixed(2)}</td>
                      <td className="table_body">{Number(row.igst) / 2}</td>
                      <td className="table_body">
                        {Number(row.cgst_amount).toFixed(2)}
                      </td>
                      <td className="table_body">{Number(row.igst) / 2}</td>
                      <td className="table_body">
                        {Number(row.sgst_amount).toFixed(2)}
                      </td>
                      <td className="table_body">
                        {row.total_amount.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  {/* For total taxable value */}
                  <tr>
                    <td className="table_body"></td>
                    <td className="table_body">
                      <span style={{ display: index_display }}></span>
                    </td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body">
                      <span style={{ display: discount_display }}></span>
                    </td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body" colSpan={2}>
                      Taxable Value
                    </td>
                    <td className="table_body" colSpan={2}>
                      {total_taxable_amount.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_body"></td>
                    <td className="table_body">
                      <span style={{ display: index_display }}></span>
                    </td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body">
                      <span style={{ display: discount_display }}></span>
                    </td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body" colSpan={2}>
                      Total Taxes
                    </td>
                    <td className="table_body" colSpan={2}>
                      {total_taxes.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_body"></td>
                    <td className="table_body">
                      <span style={{ display: index_display }}></span>
                    </td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body">
                      <span style={{ display: discount_display }}></span>
                    </td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body"></td>
                    <td className="table_body" colSpan={2}>
                      Total
                    </td>
                    <td className="table_body" colSpan={2}>
                      {Number(data.final_amount).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_body">
                      <span style={{ display: index_display }}></span>
                    </td>
                    <td className="table_body" colSpan="7">
                      <Text
                        style={{
                          fontFamily: "open sans",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        Amount in words-:
                      </Text>
                    </td>
                    <td className="table_body"></td>
                    <td className="table_body">
                      <span style={{ display: discount_display }}></span>
                    </td>
                    <td className="table_body" colSpan={2}>
                      Total Discount
                    </td>
                    <td className="table_body" colSpan={2}>
                      {Number(data.discount_amount).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_body">
                      <span style={{ display: index_display }}></span>
                    </td>
                    <td className="table_body" colSpan="7">
                      <Text
                        style={{
                          fontFamily: "open sans",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        {price_in_words2(
                          Number(
                            data.final_amount - data.discount_amount
                          ).toFixed(2)
                        )}
                      </Text>
                    </td>
                    <td className="table_body">
                      <span style={{ display: discount_display }}></span>
                    </td>
                    <td className="table_body"></td>
                    <td className="table_body" colSpan={2}>
                      <Text
                        style={{
                          fontFamily: "open sans",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        Total Amount
                      </Text>
                    </td>
                    <td
                      className="table_body"
                      style={{ borderBottom: "2px solid #A64D79" }}
                      colSpan={2}
                    >
                      <Text
                        style={{
                          fontFamily: "open sans",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {Number(
                          data.final_amount - data.discount_amount
                        ).toFixed(2)}
                      </Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* For view the company bank details only for gst invoice */}
          <div>
            <Grid>
              <Grid.Col span={6}>
                <div className="print_border">
                  <Text
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "30px",
                      fontWeight: 500,
                      lineHeight: 1.2,
                    }}
                  >
                    Bank Details :
                  </Text>
                </div>
              </Grid.Col>
              <Grid.Col span={6}>
                <div className="print_border">
                  <Text
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "30px",
                      fontWeight: 500,
                      lineHeight: 1.2,
                    }}
                  >
                    Tax Details :
                  </Text>
                </div>
              </Grid.Col>
            </Grid>
          </div>
          <div>
            <Grid>
              <Grid.Col span={6}>
                <div>
                  <p
                    style={{
                      lineHeight: "18px",
                      margin: 0,
                      fontSize: "12px",
                      fontFamily: "open sans",
                    }}
                  >
                    ACCOUNT NAME : IMPRULIFE VENTURE PRIVATE LIMITED <br />
                    ACCOUNT NUMBER : 0760261001208 (O/D ACCOUNT) <br /> BANK
                    NAME-: CANARA BANK <br /> IFSC CODE-: CNRB0000760 <br />
                    SWIFT CODE-: CNRBINBBRFM <br /> BRANCH-: Uppoor <br />
                    BRANCH ADDRESS-: NEELIMA, KG ROAD, THENKABETTU PO UPPOOR,
                    UDUPI DIST, KG ROAD,THENKABETTU PO UPPOOR, UDUPI DIST,
                    KARNATAKA -576125, INDIA
                  </p>
                </div>
              </Grid.Col>
              <Grid.Col span={6}>
                {/* Table for view the tax of overall sale with percentage wise */}
                <div>
                  <table className="print_table1">
                    <thead>
                      <tr>
                        <th className="table_th">Tax%</th>
                        <th className="table_th">Taxable</th>
                        <th className="table_th">SGST%</th>
                        <th className="table_th">Amt</th>
                        <th className="table_th">CGST%</th>
                        <th className="table_th">Amt</th>
                        <th className="table_th">IGST</th>
                        <th className="table_th">Amt</th>
                        <th className="table_th">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table_td">5%</td>
                        <td className="table_td">
                          {parseFloat(
                            five.reduce(function (sum, current) {
                              return (
                                +Number(sum) + Number(current.total_amount)
                              );
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">2.5%</td>
                        <td className="table_td">
                          {parseFloat(
                            five.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.sgst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">2.5%</td>
                        <td className="table_td">
                          {parseFloat(
                            five.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.cgst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">5%</td>
                        <td className="table_td">
                          {parseFloat(
                            five.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.igst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">
                          {parseFloat(
                            five.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.igst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="table_td">12%</td>
                        <td className="table_td">
                          {parseFloat(
                            twelve.reduce(function (sum, current) {
                              return (
                                +Number(sum) + Number(current.total_amount)
                              );
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">6%</td>
                        <td className="table_td">
                          {parseFloat(
                            twelve.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.cgst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">6%</td>
                        <td className="table_td">
                          {parseFloat(
                            twelve.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.sgst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">12%</td>
                        <td className="table_td">0.00</td>
                        <td className="table_td">
                          {parseFloat(
                            twelve.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.igst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="table_td">18%</td>
                        <td className="table_td">
                          {parseFloat(
                            fifteen.reduce(function (sum, current) {
                              return (
                                +Number(sum) + Number(current.total_amount)
                              );
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">9%</td>
                        <td className="table_td">
                          {parseFloat(
                            fifteen.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.cgst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">9%</td>
                        <td className="table_td">
                          {parseFloat(
                            fifteen.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.sgst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                        <td className="table_td">18%</td>
                        <td className="table_td">0.00</td>
                        <td className="table_td">
                          {parseFloat(
                            fifteen.reduce(function (sum, current) {
                              return +Number(sum) + Number(current.igst_amount);
                            }, 0)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid.Col>
            </Grid>
          </div>

          <div
            className="print_border"
            style={{ width: "50%", marginTop: 10, marginBottom: 5 }}
          >
            <Text
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "30px",
                fontWeight: 500,
                lineHeight: 1.2,
              }}
            >
              Notice:
            </Text>
          </div>
          <div className="print_border">
            <List
              style={{
                lineHeight: "18px",
                margin: 0,
                fontSize: "12px",
                fontFamily: "open sans",
              }}
            >
              <List.Item>
                Goods once sold are not returnable & non refundable
              </List.Item>
              <List.Item>Subject to Udupi Jurisdiction Only</List.Item>
              {rows.length > 0 ? <>{rows}</> : null}
            </List>
          </div>
          <div
            style={{
              padding: 10,
              fontSize: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px",
                lineHeight: 1.2,
              }}
            >
              This is computer generated invoice no signature required
            </Text>
            <Text>&nbsp;</Text>
            <Text>&nbsp;</Text>
          </div>
        </div>
      ) : null}

      <div className="noprint">
        <div>
          <Grid>
            <Grid.Col span={6}>
              <div
                style={{
                  textAlign: "left",
                }}
                className="noprint"
              >
                <Button
                  type="submit"
                  color="blue"
                  variant="outline"
                  mt={15}
                  mr={10}
                  onClick={() =>
                    setVariables({
                      ...variables,
                      addDrawer: true,
                    })
                  }
                >
                  <ListCheck size={18} /> &nbsp; Add Notes
                </Button>
              </div>
            </Grid.Col>
            <Grid.Col span={6}>
              <div
                style={{
                  textAlign: "right",
                }}
                className="noprint"
              >
                <Button
                  type="submit"
                  color="dark"
                  mt={15}
                  onClick={() => {
                    window.print();
                  }}
                >
                  <Printer size={18} /> &nbsp; Print
                </Button>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      </div>

      {/* Add Notes Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Additional Notes"}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form onSubmit={form.onSubmit((values) => handleNotes(values))}>
              <Grid>
                <Grid.Col md={12} lg={12}>
                  <Textarea
                    label="Notes"
                    withAsterisk
                    required
                    value={form.values.notes}
                    placeholder="Enter notes:"
                    {...form.getInputProps("notes")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    color="zevcore"
                  >
                    Submit
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Notes Modal */}
    </div>
  );
}

export default PrintSales;
