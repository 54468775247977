/**

    * Application Name: Imprulife Venture Pvt Ltd Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 03.04.2023
    * Last Modified Date: 03.04.2023
    * Developer Name: Anand_Krishna

**/

import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// Function for user doesn't have a token and try to login to dashboard will be redirected to login page.
// Start
function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const location = useLocation();

  if (isAuthenticated == null) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    if (isAuthenticated === "true") {
      return children;
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }
}

export default ProtectedRoute;
// End
