import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs"; // For breadcrumbs data import
import useStyles from "../../components/Styles/Style"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Drawer,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  Menu,
  ActionIcon,
  Progress,
  Paper,
  Select,
} from "@mantine/core"; //For import mantine required functions and theme // Mantine all required compoennts imports list
import {
  Cash,
  CloudUpload,
  Dots,
  Eye,
  Pencil,
  Search,
  Trash,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { CSVLink } from "react-csv"; // For export the data to excel format
import {
  handleDeleteInvoice,
  handleGetHSNReport,
  handleSearchHSNReport,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../helpers/notifications"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import { dataSlice, onDownload, selectFilter } from "../../helpers/common"; // Common fuctions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../assets/images/excel.png"; // Image for excel export
import pdf from "../../assets/images/pdf.png"; // Image for pdf exports
import { useInterval } from "@mantine/hooks";
import readXlsxFile from "read-excel-file";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";

function HSN() {
  // Mantine customer style use
  const { classes } = useStyles();
  const modals = useModals();
  let navigate = useNavigate();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [sortBy, setSortBy] = useState(null); // Seting the sortby table type
  const [reverseSortDirection, setReverseSortDirection] = useState(false); // For set the reverse sort direction
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [firstDate, setFirstDate] = useState("");
  // Product golden
  const [paymentModal, setPaymentModal] = useState(false);
  const [goldTitle, setGoldTitle] = useState("");
  const [goldValue, setGoldValue] = useState("");
  const [goldData, setGoldData] = useState("");
  const [dateRange, setDateRange] = useState("2023");
  const [dateRange2, setDateRange2] = useState(
    "0" + new Date().getMonth().toString()
  );
  const [customer, setCustomer] = useState("");
  // Setting the variables data list here

  const [month, setMonth] = useState([
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ]);

  const [years, setYears] = useState([
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ]);

  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    customer: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });

  // For form validation
  const formSearch = useForm({
    initialValues: {
      month: "",
      year: "",
    },
  });

  //   For intial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        const response = await handleGetHSNReport();
        console.log(response.data.data);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables((variables) => {
            return {
              ...variables,
              data: response.data.data,
              skeletonLoading: false,
            };
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          console.log(datas);
          setSortedData(datas);
          formSearch.setFieldValue("year", new Date().getFullYear().toString());
          formSearch.setFieldValue(
            "month",
            "0" + (new Date().getMonth() + 1).toString()
          );
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td>{row.hsn_code}</td>
      <td>--</td>
      <td>{row.total_quantity}</td>
      <td>{Number(row.total_taxable_value).toFixed(2)}</td>
      <td>{Number(row.total_taxable_value).toFixed(2)}</td>
      <td>{Number(row.total_igst_amount).toFixed(2)}</td>
      <td>{Number(row.total_cgst_amount).toFixed(2)}</td>
      <td>{Number(row.total_sgst_amount).toFixed(2)}</td>
      <td>--</td>
    </tr>
  ));

  const SearchInvoice = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleSearchHSNReport(e);
    // Check for response for actions
    // On Response setting the data to variable
    if (response.status === 200) {
      setVariables((variables) => {
        return {
          ...variables,
          data: response.data.data,
          skeletonLoading: false,
          submitLoading: false,
        };
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      console.log(datas);
      setSortedData(datas);
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Monthly HSN Report" />
      </Skeleton>

      <Paper mb={10} p={15} className="border">
        <form onSubmit={formSearch.onSubmit((values) => SearchInvoice(values))}>
          <Group>
            <Select
              label="Select Year"
              variant="filled"
              value={dateRange}
              onChange={setDateRange}
              placeholder="Select Year"
              data={years}
              {...formSearch.getInputProps("year")}
            />
            <Select
              label="Select Month"
              variant="filled"
              value={dateRange2}
              onChange={setDateRange2}
              placeholder="Select Month"
              data={month}
              {...formSearch.getInputProps("month")}
            />
            <Button
              loading={variables.submitLoading}
              type="submit"
              mt={22}
              leftIcon={<Search size={14} />}
            >
              Search
            </Button>
          </Group>
        </form>
      </Paper>

      {/* Main start here */}
      <Skeleton
        radius="md"
        className="CardOverride"
        visible={variables.skeletonLoading}
      >
        <Card className="border CardOverride">
          <div>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="CardOverride"
            >
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search by any field"
                mb="md"
                size="xs"
                value={search}
                icon={<Search size={14} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "value", key: "value" },
                      { label: "HSN", key: "hsn_code" },
                      { label: "UQC", key: "--" },
                      { label: "Total Quantity", key: "total_quantity" },
                      {
                        label: "Total Taxable Value",
                        key: "total_taxable_value",
                      },
                      { label: "Rate", key: "cgst" },
                      { label: "Integrated Tax", key: "total_igst_amount" },
                      { label: "Central Tax", key: "total_cgst_amount" },
                      { label: "State Tax", key: "total_sgst_amount" },
                      { label: "Cess", key: "--" },
                    ]}
                    filename="hsn_report.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>HSN</th>
                  <th>UQC</th>
                  <th>Total Quantity</th>
                  <th>Total Taxable Value</th>
                  <th>Rate</th>
                  <th>Integrated Tax</th>
                  <th>Central Tax</th>
                  <th>State Tax</th>
                  <th>Cess</th>
                </tr>
              </thead>
              {/* Table body defines from rows function */}
              <tbody key={refreshTable}>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td>
                      <Text weight={500} align="center">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
        </Card>
      </Skeleton>
    </div>
  );
}
export default HSN;
