import {
  Avatar,
  Button,
  Card,
  FileButton,
  Grid,
  Group,
  Paper,
  Skeleton,
  Text,
} from "@mantine/core";
import React, { useState, useEffect, useRef, useCallback } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
// for image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import useStyles from "../Styles/Style.jsx"; // Import custom styles
import { CloudUpload, Download, Upload, X } from "tabler-icons-react";
import { URL_CONSTANTS } from "../../constants/constants.js";
//
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const Cropper = (profileimage, imageTitle) => {
  const { classes, theme } = useStyles();
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    groupList: [],
    subgroupList: [],
    taxList: [],
    hsnList: [],
    data: [],
    addDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          {/* For cropper */}
          {upImg !== "" && upImg !== null ? (
            <>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={upImg}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
              <div>
                {previewCanvasRef != null ? (
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0),
                      marginBottom: 50,
                      display: "none",
                    }}
                  />
                ) : null}

                <Group position="right" mt="md" mb={20}>
                  <Button
                    type="submit"
                    color="zevcore"
                    onClick={() => {
                      setUpImg("");
                      setImage("");
                    }}
                  >
                    Clear Image
                  </Button>
                </Group>
              </div>
            </>
          ) : (
            // For selecting cropping image dropdown
            <div
              style={{
                marginTop: 15,
                position: "relative",
                marginBottom: 30,
              }}
            >
              <Paper
                p="lg"
                sx={(theme) => ({
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[8]
                      : theme.white,
                })}
              >
                <Avatar
                  src={URL_CONSTANTS.CUSTOMER_IMAGE + profileimage}
                  size={150}
                  radius={100}
                  mx="auto"
                />
                <Text ta="center" fz="lg" weight={500} mt="md">
                  {imageTitle}
                </Text>

                <Group position="center">
                  <FileButton
                    resetRef={ref}
                    onChange={changeHandler}
                    accept={[
                      "image/png",
                      "image/jpeg",
                      "image/sgv+xml",
                      "image/gif",
                    ]}
                  >
                    {(props) => (
                      <Button mt={20} {...props}>
                        Select Image
                      </Button>
                    )}
                  </FileButton>
                </Group>
              </Paper>
            </div>
          )}
        </Card>
      </Skeleton>
    </div>
  );
};
export default Cropper;
