import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs"; // For breadcrumbs data import
import useStyles from "../../components/Styles/Style"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Drawer,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  Menu,
  ActionIcon,
  Progress,
  Paper,
  Select,
  Avatar,
  Anchor,
  Loader,
  Badge,
  Grid,
} from "@mantine/core"; //For import mantine required functions and theme // Mantine all required compoennts imports list
import {
  Cash,
  CloudUpload,
  Dots,
  Eye,
  Pencil,
  Printer,
  Search,
  Trash,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { CSVLink } from "react-csv"; // For export the data to excel format
import {
  handleDeleteInvoice,
  handleGetCustomer,
  handleGetInvoice,
  handleGetOneInvoice,
  handleGetTwoDate,
  handleGetWeeklyReport,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../helpers/notifications"; // Import notification for this page
import { Th, dataSearch, setSorting, print } from "../../helpers/tableFunction"; // For table data functions
import { dataSlice, onDownload, selectFilter } from "../../helpers/common"; // Common fuctions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../assets/images/excel.png"; // Image for excel export
import pdf from "../../assets/images/pdf.png"; // Image for pdf exports
import { useInterval } from "@mantine/hooks";
import readXlsxFile from "read-excel-file";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components
import { DateInput, DatePicker, DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { URL_CONSTANTS } from "../../constants/constants";

function Weekly() {
  // Mantine customer style use
  const { classes } = useStyles();
  const modals = useModals();
  let navigate = useNavigate();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [sortedData2, setSortedData2] = useState([]); // For table data
  const [loadingData, setLoadingData] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [sortBy, setSortBy] = useState(null); // Seting the sortby table type
  const [reverseSortDirection, setReverseSortDirection] = useState(false); // For set the reverse sort direction
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [firstDate, setFirstDate] = useState("");
  // Product golden
  const [paymentModal, setPaymentModal] = useState(false);
  const [goldTitle, setGoldTitle] = useState("");
  const [goldValue, setGoldValue] = useState("");
  const [goldData, setGoldData] = useState("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [customer, setCustomer] = useState("");
  const [totalSales, setTotalSales] = useState(0);
  const [earning, setEarning] = useState(0);
  const [due, setDue] = useState(0);
  // Setting the variables data list here

  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    customer: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });

  // Form values for adding state values
  const formSearch = useForm({
    initialValues: {
      value: "",
    },
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        //   For get all the invoice data list
        const response = await handleGetWeeklyReport();
        // On Response setting the data to variable
        console.log(response.data.data);
        if (response.status === 200) {
          setVariables((variables) => {
            return {
              ...variables,
              data: response.data.data[0],
              skeletonLoading: false,
            };
          });
          const datas = dataSlice({
            data: response.data.data[0],
            page: 1,
            total: 10,
          });
          setSortedData(datas);
          setTotalSales(response.data.data[1]);
          setEarning(response.data.data[2][0].total_amount);
          setDue(response.data.data[2][0].total_balance);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const searchInvoive = async (e) => {
    const req = {
      date1: new Date(dateRange[0]),
      date2: new Date(dateRange[1]),
    };
    setVariables({ ...variables, submitLoading: true });
    const response = await handleGetTwoDate(req);
    // Check for response for actions
    if (response.status === 200) {
      setVariables((variables) => {
        return {
          ...variables,
          data: response.data.data[0],
          submitLoading: false,
        };
      });
      const datas = dataSlice({
        data: response.data.data[0],
        page: 1,
        total: 10,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Table data arrabnge by using function and loop throw each data rrange to table body
  const rows = sortedData.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.customer.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.CUSTOMER_IMAGE + row.customer.image,
                  title: row.customer.label,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.CUSTOMER_IMAGE + row.customer.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.CUSTOMER_IMAGE + row.customer.image,
                  title: row.customer.label,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.CUSTOMER_IMAGE + row.customer.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor component="button" size="sm">
                {row.customer.label}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              Customer Group: {row.customer.group.label}
            </Text>
          </div>
        </Group>
      </td>
      <td>{row.customer.gstin !== null ? row.customer.gstin : "--"}</td>
      <td>{new Date(row.invoice_date).toLocaleDateString("en-IN")}</td>
      <td>{row.invoice_number}</td>
      <td>
        {row.invoice_type === "Inclusive Tax" ? (
          <Badge color="green">GST Inclusive</Badge>
        ) : (
          <Badge color="yellow">GST Exclusive</Badge>
        )}
      </td>
      <td>{formatter.format(row.final_amount)}</td>
      <td>
        {row.payment_status === "Completed" ? (
          <Badge color="green">{row.payment_status}</Badge>
        ) : (
          <Badge color="red">{row.payment_status}</Badge>
        )}
      </td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => navigate("/print_invoice/" + row.value)}>
            <Printer size={18} color="gray" />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Weekly Sales Report" />
      </Skeleton>
      <Paper mb={10} p={15} className="border">
        <form onSubmit={formSearch.onSubmit((values) => searchInvoive(values))}>
          <Group>
            <DatePickerInput
              variant="filled"
              label="Pick Week "
              type="range"
              placeholder="Pick dates range"
              value={dateRange}
              onChange={setDateRange}
              maw={400}
            />
            <Button
              loading={variables.submitLoading}
              type="submit"
              mt={22}
              leftIcon={<Search size={14} />}
            >
              Search
            </Button>
          </Group>
        </form>
      </Paper>
      <Paper mb={10} p={15} className="border">
        <Grid>
          <Grid.Col xs={4}>
            <Card
              withBorder
              radius="md"
              padding="xl"
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[7]
                    : theme.white,
              })}
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                TOTAL SALES
              </Text>
              <Text fz="lg" fw={500}>
                {totalSales}
              </Text>
              <Progress value={54.31} mt="md" size="lg" radius="xl" />
            </Card>
          </Grid.Col>
          <Grid.Col xs={4}>
            <Card
              withBorder
              radius="md"
              padding="xl"
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[7]
                    : theme.white,
              })}
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                TOTAL EARNINGS
              </Text>
              <Text fz="lg" fw={500}>
                ₹ {Number(earning).toFixed(2)}
              </Text>
              <Progress value={54.31} mt="md" size="lg" radius="xl" />
            </Card>
          </Grid.Col>
          <Grid.Col xs={4}>
            <Card
              withBorder
              radius="md"
              padding="xl"
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[7]
                    : theme.white,
              })}
            >
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                TOTAL DUE AMOUNT
              </Text>
              <Text fz="lg" fw={500}>
                ₹ {Number(due).toFixed(2)}
              </Text>
              <Progress value={54.31} mt="md" size="lg" radius="xl" />
            </Card>
          </Grid.Col>
        </Grid>
      </Paper>
      {/* Main start here */}
      <Skeleton
        radius="md"
        className="CardOverride"
        visible={variables.skeletonLoading}
      >
        <Card className="border CardOverride">
          <div>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="CardOverride"
            >
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search by any field"
                mb="md"
                size="xs"
                value={search}
                icon={<Search size={14} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "value", key: "value" },
                      { label: "customer name", key: "customer.label" },
                      { label: "customer GST", key: "customer.gstin" },
                      { label: "invoice date", key: "invoice_date" },
                      { label: "invoice number", key: "invoice_number" },
                      { label: "invoice type", key: "invoice_type" },
                      { label: "total amount", key: "final_amount" },
                      { label: "payment status", key: "payment_status" },
                    ]}
                    filename="weekly_report.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Customer Name</th>
                  <th>Customer GST</th>
                  <th>Invoice Date</th>
                  <th>Invoice Number</th>
                  <th>Invoice Type</th>
                  <th>Total Amount</th>
                  <th>Payment Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>
                  {rows}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div style={{ textAlign: "center", fontWeight: "bold" }}>
                        Total Earnings
                      </div>
                    </td>
                    <td>{formatter.format(earning)}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              ) : (
                <>
                  {loadingData === true ? (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#043c64" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </div>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
        </Card>
      </Skeleton>
    </div>
  );
}
export default Weekly;
