/**
 * Application Name: Zevcore-WAS
 * Author: Zevcore Private Limited
 * Starting date: 15.12.2022
 * Developer Name: Anand Krishna
 * Last modified date: 10.09.2022
 * Last modified by: Anand Krishna
 */

import {
  ActionIcon,
  AppShell,
  Header,
  useMantineTheme,
  Menu,
  Text,
  Button,
  TextInput,
  Indicator,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme
import React, { useState, useEffect } from "react";
import { ModalsProvider } from "@mantine/modals";
import { openConfirmModal } from "@mantine/modals";
import { MoonStars, Sun, Settings, Logout, X, Bell } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import { Search } from "tabler-icons-react";
import { useLocalStorage } from "@mantine/hooks";
import { useNavigate, Outlet } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import { SpotlightProvider, openSpotlight } from "@mantine/spotlight"; // For enable search from navigation
// For imports the logo images for navigation
import Navigation from "./Navbarlink";
import Logo from "../../assets/images/logo.png";

// Sidebar start
function Sidebar() {
  const [minimize, setMinimize] = useState(false);
  const theme = useMantineTheme();
  const [statusText, setStatusText] = useState("");
  const [update, setUpdate] = useState(0);
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const dark = colorScheme === "dark";

  const [note, setNote] = useState(true);
  const [pCount, setPCount] = useLocalStorage({
    key: "depletion",
    defaultValue: 0,
  });
  const [notificationDrawer, setNotificationDrawer] = useState(false);
  let navigate = useNavigate();

  // For search tooltip of navigation list
  const actions = [
    {
      title: "Dashboard",
      onTrigger: () => navigate("/"),
    },
    {
      title: "Customers",
      onTrigger: () => navigate("/customers"),
    },

    { title: "Invoices", onTrigger: () => navigate("/view_sales") },
    { title: "Category", onTrigger: () => navigate("/category") },
    { title: "Products", onTrigger: () => navigate("/products") },
    { title: "Customer Group", onTrigger: () => navigate("/groups") },
    {
      title: "Add GST Inclusive",
      onTrigger: () => navigate("/add_inclusive_sales"),
    },
    {
      title: "Add GST Exclusive",
      onTrigger: () => navigate("/add_exclusive_sales"),
    },
    {
      title: "Weekly Report",
      onTrigger: () => navigate("/weekly_sales"),
    },
    {
      title: "Monthly Report",
      onTrigger: () => navigate("/monthly_sales"),
    },
    {
      title: "Yearly Report",
      onTrigger: () => navigate("/yearly_sales"),
    },
    {
      title: "HSN Report",
      onTrigger: () => navigate("/hsn_report"),
    },
  ];

  const handleReload = async (e) => {};
  const handleMinimize = async (e) => {};
  const handleUnmaximize = async (e) => {};
  const handleMaximize = async (e) => {};
  const handleHide = async (e) => {};
  const handleClose = async (e) => {};
  const handlePath = async (e) => {};
  const UpdateThisApplication = async (e) => {};

  const [visible, setVisible] = useState(false);
  const customLoader = (
    <svg
      width="54"
      height="54"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke={"#043c64"}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );

  const [color] = useLocalStorage({
    key: "color",
    defaultValue: "#043c64",
  });

  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  };

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          fontFamily: "Poppins",
          fontWeight: 300,
          colorScheme,
          colors: {
            zevcore: [
              LightenDarkenColor(color, 140),
              LightenDarkenColor(color, 130),
              LightenDarkenColor(color, 120),
              LightenDarkenColor(color, 110),
              LightenDarkenColor(color, 100),
              LightenDarkenColor(color, 90),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 80),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 20),
            ],
          },
          primaryColor: "zevcore",
        }}
      >
        {/* Main start */}
        <ModalsProvider>
          <AppShell
            styles={(theme) => ({
              main: {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : theme.colors.gray[0],
              },
            })}
            // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
            navbarOffsetBreakpoint="sm"
            // fixed prop on AppShell will be automatically added to Header and Navbar
            fixed
            header={
              <Header
                height={62}
                py={2}
                style={{
                  borderBottom: "0px",
                }}
                className="border-bottom noprint"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* Navigation start */}
                  <div
                    style={{
                      backgroundColor:
                        theme.colorScheme === "dark" ? "#2d2d2d" : "#EEEEEE",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 13,
                    }}
                  >
                    <div>
                      {dark ? (
                        <img src={Logo} alt="" style={{ height: "35px" }} />
                      ) : (
                        <img src={Logo} alt="" style={{ height: "35px" }} />
                      )}
                    </div>
                    <div
                      className="no-drag"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {/* Show all the list of navigation */}
                      <Navigation />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {/* Dark light switch */}
                      <ActionIcon
                        className="zc-mt-1 no-drag"
                        color={dark ? "yellow" : "blue"}
                        onClick={() => toggleColorScheme()}
                        title="Toggle dark light"
                      >
                        {dark ? (
                          <Sun strokeWidth="0.8" size={20} />
                        ) : (
                          <MoonStars strokeWidth="0.8" size={20} />
                        )}
                      </ActionIcon>
                      {/* refresh page */}
                      {/* Bell icon for depletion indicated */}
                      <ActionIcon
                        className="zc-mt-1 no-drag"
                        color="dark"
                        onClick={() =>
                          setNotificationDrawer(!notificationDrawer)
                        }
                        title="Notification"
                      >
                        <Indicator disabled={note} color="red">
                          <Bell strokeWidth="0.8" size={20} />
                        </Indicator>
                      </ActionIcon>

                      {/* For Profile Dropdown list */}
                      <Menu shadow="md" className="no-drag zc-mr-1" width={200}>
                        <Menu.Target>
                          <ActionIcon
                            className="zc-mt-1"
                            color="dark"
                            type="button"
                            title="Setting"
                          >
                            <Settings strokeWidth="0.8" size={20} />
                          </ActionIcon>
                        </Menu.Target>
                        {/* Profile setting page */}
                        <Menu.Dropdown>
                          <Menu.Item
                            icon={<Settings size={14} />}
                            onClick={() => navigate("/manages")}
                          >
                            Settings
                          </Menu.Item>
                          {/* For logout button */}
                          <Menu.Item
                            onClick={() => {
                              openConfirmModal({
                                title: "Confirm Logout ",
                                children: (
                                  <Text size="sm">
                                    Are you sure you want to logout?
                                  </Text>
                                ),

                                labels: {
                                  confirm: "Logout",
                                  cancel: "Cancel",
                                },
                                confirmProps: { color: "red" },
                                onCancel: () => console.log("Cancel"),
                                onConfirm: () => {
                                  localStorage.clear();
                                  navigate("/login");
                                },
                              });
                            }}
                            color="red"
                            icon={<Logout size={14} />}
                          >
                            Logout
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                      {/* For backup db to zip */}
                      {/* For search bar with shot cut key */}
                      <SpotlightProvider
                        className="no-drag"
                        actions={actions}
                        searchPlaceholder="Search..."
                        // shortcut={["ctrl + k", "mod + K", "⌘ + K"]}
                        nothingFoundMessage="Nothing found..."
                      >
                        <div
                          style={{ width: 150 }}
                          className="no-drag"
                          onClick={openSpotlight}
                        >
                          <TextInput
                            variant="filled"
                            placeholder="Search"
                            onClick={openSpotlight}
                            size="xs"
                            pt={2}
                            pb={1}
                            // shortcut={["ctrl + k", "mod + K", "⌘ + K"]}
                            icon={<Search size={12} stroke={1.5} />}
                            rightSectionWidth={70}
                            // rightSection={<Code>Ctrl + K</Code>}
                          />
                        </div>
                      </SpotlightProvider>
                    </div>
                  </div>
                </div>
              </Header>
            }
          >
            <Outlet />
          </AppShell>
        </ModalsProvider>
        {/* </NotificationsProvider> */}
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default Sidebar;
