/**
 * Application Name: Zevcore-WAS
 * Author: Zevcore Private Limited
 * Starting date: 10.08.2022
 * Developer Name: Suhas S
 * Last modified date: 10.09.2022
 * Last modified by: Suhas S
 */

import { Button, Menu } from "@mantine/core"; //For import mantine required functions and theme
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components
import {
  Archive,
  Box,
  ChartLine,
  Dashboard,
  FileInvoice,
  Report,
  Settings,
  User,
  UserCircle,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import useStyles from "../Styles/Style"; // Import custom styles

function Navigation() {
  // For navigation
  let navigate = useNavigate();
  const { classes } = useStyles();
  const [active, setActive] = useState("");

  // Handel click if e empty or undefined it wont do any thing
  const handlePage = (e) => {
    if (typeof e != "undefined") {
      navigate(e);
      setActive(e);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
      }}
    >
      {/* Dashboard */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          color="zevcore"
          variant="subtle"
          className={
            active === "/" ? classes.navButtonActive : classes.navButton
          }
          size="xs"
          onClick={() => handlePage("/")}
          leftIcon={<Dashboard size={14} />}
        >
          Dashboard
        </Button>

        {/* Customer */}
        <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/groups" || active === "/customers"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<UserCircle size={14} />}
            >
              Customers
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item color="zevcore" onClick={() => handlePage("/groups")}>
              View Customer Group
            </Menu.Item>
            <Menu.Item color="zevcore" onClick={() => handlePage("/customers")}>
              View Customers
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        {/* Sales */}
        <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/add_inclusive_sales" ||
                active === "/add_exclusive_sales" ||
                active === "/view_sales"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<FileInvoice size={14} />}
            >
              Invoices
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/add_inclusive_sales")}
            >
              Add GST Inclusive
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/add_exclusive_sales")}
            >
              Add GST Exclusive
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/view_sales")}
            >
              View Invoices
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        {/* Product */}
        <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/category" || active === "/products"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<Box size={14} />}
            >
              Products
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item color="zevcore" onClick={() => handlePage("/category")}>
              View Categories
            </Menu.Item>
            <Menu.Item color="zevcore" onClick={() => handlePage("/products")}>
              View Products
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        {/* Report */}
        <Menu width={200} shadow="md" position="bottom-start" withArrow>
          <Menu.Target>
            <Button
              color="zevcore"
              className={
                active === "/weekly_sales" ||
                active === "/monthly_sales" ||
                active === "/yearly_sales" ||
                active === "/hsn_report"
                  ? classes.navButtonActive
                  : classes.navButton
              }
              variant="subtle"
              size="xs"
              leftIcon={<Report size={14} />}
            >
              Reports
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/weekly_sales")}
            >
              Weekly Report
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/monthly_sales")}
            >
              Monthly Report
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/yearly_sales")}
            >
              Yearly Report
            </Menu.Item>
            <Menu.Item
              color="zevcore"
              onClick={() => handlePage("/hsn_report")}
            >
              HSN Report
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        {/* Settings */}
        <Button
          color="zevcore"
          variant="subtle"
          className={
            active === "/manages" ? classes.navButtonActive : classes.navButton
          }
          size="xs"
          onClick={() => handlePage("/manages")}
          leftIcon={<Settings size={14} />}
        >
          Settings
        </Button>
      </div>
    </div>
  );
}

export default Navigation;
