/**
 * Application Name: JanSarkar(Mantine Version 6.0.1)
 * Author: Zevcore Private Limited
 * Starting date: 14.03.2023
 * Developer Name: Anand Krishna
 * Last modified date: 14.03.2023
 * Last modified by: Anand Krishna
 **/

import {
  Button,
  Container,
  createStyles,
  Paper,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form"; // Mantine form import
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logins from "../../assets/images/bg.jpg";
import Logo from "../../assets/images/logo.png";
import { handleLogin } from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Helper for import the axios request in open page
import { notificationHelper } from "../../helpers/notifications";
import useStyles from "../../components/Styles/Style"; // Import custom styles

export default function Login() {
  let navigate = useNavigate();
  // Mantine custome style use
  const { classes } = useStyles();

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    submitLoading: false,
    userCount: 0,
  });

  // For form validation
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (value.length < 1 ? "Email is required" : null),
      password: (value) => (value.length < 1 ? "Password is required" : null),
    },
  });

  // Login function
  // Submit the login information
  const handleSubmit = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleLogin(e);
    if (response.status === 200) {
      console.log(response.data.roles);
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("role", response.data.roles);
      localStorage.setItem("isAuthenticated", "true");
      setTimeout(() => {
        navigate("/");
      }, 300);
      notificationHelper({
        color: "green",
        title: "Success",
        message: "You have logged in successfully",
      });
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed!",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <div className={classes.wrapper}>
      <Container
        style={{ fontFamily: "Verdana, sans-serif" }}
        size={480}
        my={100}
      >
        <Paper withBorder shadow="md" radius="md" p={30}>
          <Text
            mt={10}
            mb={10}
            sx={{
              color: "#3d3d3d",
              fontWeight: "bold",
              fontSize: 22,
              textAlign: "center",
            }}
            size="xl"
          >
            LOGIN
          </Text>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              variant="filled"
              value={form.values.email}
              label="Email address or Phone No"
              placeholder="Enter Email or Phone No"
              size="md"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              variant="filled"
              label="Password"
              placeholder="Your password"
              mt="md"
              size="md"
              value={form.values.password}
              {...form.getInputProps("password")}
            />
            <Button
              fullWidth
              type="submit"
              mt="xl"
              size="md"
              color="zevcore"
              loading={variables.submitLoading}
            >
              Login
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
}
