import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  Text,
  Paper,
  Tabs,
  Card,
  Avatar,
  Modal,
  ActionIcon,
  Button,
  NativeSelect,
  Pagination,
  Skeleton,
  Group,
  Anchor,
  Badge,
  useMantineTheme,
} from "@mantine/core"; //For import mantine required functions and theme
import { openModal, closeAllModals } from "@mantine/modals";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { Link } from "react-router-dom"; // For import the react dom for navigation components
import { dataSlice, nFormatter } from "./common";
import {
  Box,
  PremiumRights,
  Printer,
  Receipt,
  Trash,
  X,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import useStyles from "../components/Styles/Style";
import ReactApexChart from "react-apexcharts";
import {
  handleGetCustomerHistoryDate,
  handleGetProductHistoryDate,
} from "./apis";
import { URL_CONSTANTS } from "../constants/constants";
import { useNavigate } from "react-router-dom";

function GoldCustomers({
  value,
  title,
  list,
  customerModal,
  setCustomerModal,
}) {
  const [data, setData] = useState("");
  const { classes } = useStyles();
  let navigate = useNavigate();
  const theme = useMantineTheme();
  const [listData, setListData] = useState([]);
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [refreshChart, setRefreshChart] = useState(Date.now());
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [sortBy, setSortBy] = useState(null); // Seting the sortby table type
  const [reverseSortDirection, setReverseSortDirection] = useState(false); // For set the reverse sort direction
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table

  const [sortedData2, setSortedData2] = useState([]);
  const [activePage2, setPage2] = useState(1); // For set table active page
  const [total2, setTotal2] = useState(10); // For set total list show in page
  const [search2, setSearch2] = useState(""); // For set the search value name of table
  const [sortBy2, setSortBy2] = useState(null); // Seting the sortby table type
  const [reverseSortDirection2, setReverseSortDirection2] = useState(false); // For set the reverse sort direction
  const [refreshTable2, setRefreshTable2] = useState(Date.now()); // For refresh table
  const [journal, setJournal] = useState("");
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      console.log(list);
      if (list != "" && typeof list != "undefined") {
        console.log(list);
        setData(list);
        setVariables({
          ...variables,
          skeletonLoading: false,
        });
        const datas = dataSlice({
          data: list[0],
          page: 1,
          total: 10,
        });
        setSortedData(datas);

        var months = list[2];
        var MData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        var BData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (let i = 1; i < 12; i++) {
          for (let j = 0; j < months.length; j++) {
            if (Number(months[j].month) == i) {
              MData[i] = +Number(MData[i]) + +Number(months[j].total_amount);
              BData[i] = +Number(BData[i]) + +Number(months[j].total_balance);
            }
          }
        }
        console.log(MData, BData);
        var dataT = series;
        dataT[0] = {
          name: "Billed",
          data: BData,
        };
        dataT[1] = {
          name: "Paid",
          data: MData,
        };
        setSeries(dataT);
        setJournal(list[2][0].total_amount);
        setRefreshTable(new Date());
        setRefreshChart(new Date());
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, [list]);

  const [series, setSeries] = useState([
    {
      name: "Billed",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Paid",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return nFormatter(val, 2);
      },
      offsetY: -20,
      style: {
        fontSize: "8px",
        colors: ["#304758"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Amounts in ₹",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "₹" + val;
        },
      },
    },
  });

  const filter = async () => {
    console.log(date1, date2);
    const req = {
      value: value,
      from: date1,
      to: date2,
    };
    const list23 = await handleGetCustomerHistoryDate(req);
    if (list23.status == 200) {
      const list2 = list23.data.data;
      console.log(list2);
      setData(list2);
      setVariables({
        ...variables,
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: list2[0],
        page: 1,
        total: 10,
      });
      setSortedData(datas);
      setRefreshTable(new Date());
    }
  };
  return (
    <>
      <Modal
        opened={customerModal}
        onClose={() => setCustomerModal(false)}
        title={title}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div>
          <div
            style={{
              padding: 15,
            }}
          >
            {typeof data[0] != "undefined" ? (
              <>
                <Grid>
                  <Grid.Col span={4}>
                    <Skeleton visible={variables.skeletonLoading}>
                      <Card p="sm" radius="md" withBorder>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ flexDirection: "column" }}>
                            <Text weight={600} size="md">
                              Items Purchased
                            </Text>

                            <Text size={12} mt={-2}>
                              {nFormatter(data[1][0].total_item, 2)}
                            </Text>
                          </div>
                          <Avatar color="violet" radius="xl" size={60}>
                            <Box size={36} />
                          </Avatar>
                        </div>
                      </Card>
                    </Skeleton>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Skeleton visible={variables.skeletonLoading}>
                      <Card p="sm" radius="md" withBorder>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ flexDirection: "column" }}>
                            <Text weight={600} size="lg">
                              Amount
                            </Text>

                            <Text size={12} mt={-2}>
                              {Number(data[1][0].total_amount).toFixed(2)}
                            </Text>
                          </div>
                          <Avatar color="orange" radius="xl" size={60}>
                            <Receipt size={36} />
                          </Avatar>
                        </div>
                      </Card>
                    </Skeleton>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Skeleton visible={variables.skeletonLoading}>
                      <Card p="sm" radius="md" withBorder>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ flexDirection: "column" }}>
                            <Text weight={600} size="lg">
                              Balance
                            </Text>

                            <Text size={12} mt={-2}>
                              {Number(data[1][0].total_balance).toFixed(2)}
                            </Text>
                          </div>
                          <Avatar color="teal" radius="xl" size={60}>
                            <PremiumRights size={36} />
                          </Avatar>
                        </div>
                      </Card>
                    </Skeleton>
                  </Grid.Col>
                </Grid>

                <Skeleton visible={variables.skeletonLoading}>
                  <Paper mb={10} p={15} className="border">
                    <Grid pt={10}>
                      <Grid.Col span={5}>
                        <DatePickerInput
                          dropdownType="modal"
                          label="From date"
                          placeholder="Pick date"
                          value={date1}
                          onChange={setDate1}
                          mx="auto"
                        />
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <DatePickerInput
                          dropdownType="modal"
                          value={date2}
                          onChange={setDate2}
                          placeholder="Pick date"
                          label="To date"
                        />
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Skeleton visible={variables.skeletonLoading}>
                          <Button
                            onClick={() => {
                              filter();
                            }}
                            mt={24}
                            fullWidth
                            color="zevcore"
                          >
                            Submit
                          </Button>
                        </Skeleton>
                      </Grid.Col>
                    </Grid>
                  </Paper>
                </Skeleton>

                <Skeleton visible={variables.skeletonLoading}>
                  <Paper mt={10} mb={10} p={15} className="border">
                    <Tabs defaultValue="sale" pt={10}>
                      <Tabs.List>
                        <Tabs.Tab value="sale">Sales</Tabs.Tab>
                        <Tabs.Tab value="graph">Graph</Tabs.Tab>
                      </Tabs.List>

                      <Tabs.Panel value="sale" pt="xs">
                        <div className="zc-golden-height">
                          <Text>List of all the customer invoices.</Text>
                          <Table
                            horizontalSpacing="md"
                            verticalSpacing="xs"
                            className={classes.striped}
                          >
                            <thead>
                              <tr>
                                <th>Sl.No</th>
                                <th>Customer Name</th>
                                <th>Customer GST</th>
                                <th>Invoice Date</th>
                                <th>Invoice Number</th>
                                <th>Invoice Type</th>
                                <th>Total Amount</th>
                                <th>Payment Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody key={refreshTable}>
                              {sortedData.map((row, index) => (
                                <tr key={row.invoice_number}>
                                  <td>
                                    {activePage * total - total + index + 1}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    <Group spacing="sm">
                                      {row.customer.image != "" ? (
                                        <Avatar
                                          onClick={() => {
                                            imageModal({
                                              data:
                                                URL_CONSTANTS.CUSTOMER_IMAGE +
                                                row.customer.image,
                                              title: row.customer.label,
                                            });
                                          }}
                                          radius={30}
                                          size={30}
                                          src={
                                            URL_CONSTANTS.CUSTOMER_IMAGE +
                                            row.customer.image
                                          }
                                        />
                                      ) : (
                                        <Avatar
                                          onClick={() => {
                                            imageModal({
                                              data:
                                                URL_CONSTANTS.CUSTOMER_IMAGE +
                                                row.customer.image,
                                              title: row.customer.label,
                                            });
                                          }}
                                          radius={30}
                                          size={30}
                                          src={
                                            URL_CONSTANTS.CUSTOMER_IMAGE +
                                            row.customer.image
                                          }
                                        />
                                      )}

                                      <div>
                                        <Text fz="sm" fw={500}>
                                          <Anchor component="button" size="sm">
                                            {row.customer.label}
                                          </Anchor>
                                        </Text>
                                        <Text c="dimmed" fz="xs">
                                          Customer Group:{" "}
                                          {row.customer.group.label}
                                        </Text>
                                      </div>
                                    </Group>
                                  </td>
                                  <td>
                                    {row.customer.gstin !== null
                                      ? row.customer.gstin
                                      : "--"}
                                  </td>
                                  <td>
                                    {new Date(
                                      sortedData.reduce(function (r, a) {
                                        return r.createdAt > a.createdAt
                                          ? r
                                          : a;
                                      }).createdAt
                                    ).toLocaleString("en-UK")}
                                  </td>
                                  <td>{row.invoice_number}</td>
                                  <td>
                                    {row.invoice_type === "Inclusive Tax" ? (
                                      <Badge color="green">
                                        GST {row.invoice_type}
                                      </Badge>
                                    ) : (
                                      <Badge color="yellow">
                                        GST {row.invoice_type}
                                      </Badge>
                                    )}
                                  </td>
                                  <td>{row.final_amount}</td>
                                  <td>
                                    {row.payment_status === "Completed" ? (
                                      <Badge color="green">
                                        {row.payment_status}
                                      </Badge>
                                    ) : (
                                      <Badge color="red">
                                        {row.payment_status}
                                      </Badge>
                                    )}
                                  </td>
                                  {/* For action drop down edit and delete the data */}
                                  <td>
                                    <Group spacing={0} position="left">
                                      <ActionIcon
                                        onClick={() =>
                                          navigate(
                                            "/print_invoice/" + row.value
                                          )
                                        }
                                      >
                                        <Printer size={18} color="gray" />
                                      </ActionIcon>
                                    </Group>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: 15,
                            }}
                          >
                            {/* For number of rows display in table */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Text size="sm" className="zc-pr-3 zc-pt-1">
                                Per Page
                              </Text>
                              <NativeSelect
                                size="xs"
                                onChange={async (e) => {
                                  setTotal(Number(e.currentTarget.value));
                                  setPage(1);
                                  const datas = await dataSlice({
                                    data: data[0],
                                    page: 1,
                                    total: Number(e.currentTarget.value),
                                  });
                                  setSortedData(datas);
                                  setRefreshTable(new Date());
                                }}
                                data={["10", "20", "50", "100"]}
                                rightSectionWidth={20}
                                sx={{ width: 70 }}
                              />
                            </div>
                            {/* For pagination */}
                            <Pagination
                              size="xs"
                              page={activePage}
                              onChange={async (e) => {
                                setPage(Number(e));
                                const datas = await dataSlice({
                                  data: data[0],
                                  page: Number(e),
                                  total: total,
                                });
                                setSortedData(datas);
                                setRefreshTable(new Date());
                              }}
                              total={Math.ceil(data[0].length / total)}
                              color="zevcore"
                            />
                          </div>
                        </div>
                      </Tabs.Panel>

                      <Tabs.Panel value="graph" pt="xs">
                        <div key={refreshChart}>
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="bar"
                            height={350}
                          />
                        </div>
                      </Tabs.Panel>
                    </Tabs>
                  </Paper>
                </Skeleton>
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default GoldCustomers;
