/**

    * Application Name: Imprulife Venture Pvt Ltd Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 03.04.2023
    * Last Modified Date: 03.04.2023
    * Developer Name: Anand_Krishna

**/

import axios from "axios";
import { URL_CONSTANTS } from "../constants/constants";

// Token
const config = {
  headers: {
    "x-access-token": localStorage.getItem("token"),
  },
};

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

// AUTH API

// For handle login
export const handleLogin = async (request) => {
  try {
    // URL for login
    const response = await axios.post(URL_CONSTANTS.LOGIN_URL, request);
    return response;
  } catch (error) {
    return error.response;
  }
};

// AUTH API

// REMINDER API

// For handle add reminder
export const handleAddReminder = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REMINDER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get reminder
export const handleGetReminder = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.REMINDER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get today's reminder
export const handleTodayReminder = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.REMINDER_URL + "/today",
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete reminder
export const handleDeleteReminder = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.REMINDER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// REMINDER API

// USER API

// For handle get state data list
export const handleGetUserOne = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.USER_ONE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit user data list
export const handleEditUser = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.USER_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// USER API

// ACCOUNT API

// For handle get account data list
export const handleGetAccount = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.ACCOUNT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get Account data list
export const handleAddAccount = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.ACCOUNT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// ACCOUNT API

// TAX API

// For handle get tax data list
export const handleGetTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.TAX_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add tax data list
export const handleAddTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.TAX_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleEditTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.TAX_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete tax data list
export const handleDeleteTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.TAX_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleBulkTax = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.TAX_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// TAX API

// HSN CODE API

// For handle get tax data list
export const handleGetHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.HSN_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add tax data list
export const handleAddHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.HSN_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleEditHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.HSN_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete tax data list
export const handleDeleteHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.HSN_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleBulkHSN = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(URL_CONSTANTS.HSN_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// HSN CODE API

// BANK API

// For handle get tax data list
export const handleGetBankOne = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.BANK_URL + "/1", config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleEditBank = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(URL_CONSTANTS.BANK_URL, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// BANK API

// PREFIX API

// For handle get tax data list
export const handleGetPrefixOne = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PREFIX_URL + "/1", config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleEditPrefix = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PREFIX_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit tax data list
export const handleUploadPrefixImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PREFIX_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PREFIX API

// GROUP API

// For handle get customer group data list
export const handleGetCustomerGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CUSTOMER_GROUP_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add customer group data list
export const handleAddCustomerGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CUSTOMER_GROUP_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit customer group data list
export const handleEditCustomerGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CUSTOMER_GROUP_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit customer group data list
export const handleDeleteCustomerGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CUSTOMER_GROUP_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit customer group data list
export const handleBulkCustomerGroup = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CUSTOMER_GROUP_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// GROUP API

// CUSTOMER API

// For handle get customer data list
export const handleGetCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CUSTOMER_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add customer data list
export const handleAddCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CUSTOMER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit customer data list
export const handleEditCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CUSTOMER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload customer image
export const handleUploadCustomerImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CUSTOMER_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit customer data list
export const handleDeleteCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CUSTOMER_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Customer data list
export const handleBulkCustomer = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CUSTOMER_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CUSTOMER API

// CATEGORY API

// For handle get category data list
export const handleGetCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.CATEGORY_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add category data list
export const handleAddCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit category data list
export const handleEditCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit category data list
export const handleDeleteCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.CATEGORY_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit category data list
export const handleBulkCategory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CATEGORY_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// CATEGORY API

// PRODUCT API

// For handle get product data list
export const handleGetProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.PRODUCT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add product data list
export const handleAddProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add product data list
export const handleSearchProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_URL + "/search",
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit product data list
export const handleEditProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle upload product image
export const handleUploadProductImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.PRODUCT_UPLOAD_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit product data list
export const handleDeleteProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.PRODUCT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit product data list
export const handleBulkProduct = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// PRODUCT API

// INVOICE API

// For handle invoice add
export const handleGetOneInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.INVOICE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.INVOICE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleAddInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.INVOICE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleUpdateInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      URL_CONSTANTS.INVOICE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleAddInvoice2 = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.INVOICE2_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For delete the invoice data
export const handleDeleteInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.INVOICE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For delete the invoice data
export const handleDeleteInvoiceTransaction = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.INVOICE_TRANSACTION_DELETE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add tax data list
export const handleAddNotes = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.INVOICE_NOTES_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// INVOICE API

// SAVE INVOICE API

// For handle save invoice add
export const handleGetOneSaveInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.SAVE_INVOICE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle save invoice add
export const handleGetSaveInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.SAVE_INVOICE_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle save invoice add
export const handleAddSaveInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SAVE_INVOICE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle save invoice add
export const handleAddSaveInvoice2 = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.SAVE_INVOICE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For delete the save invoice data
export const handleDeleteSaveInvoice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      URL_CONSTANTS.SAVE_INVOICE_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// SAVE INVOICE API

// REPORT API

// Weekly report
export const handleGetWeeklyReport = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.WEEKLY_REPORT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// Monthly report
export const handleGetMonthlyReport = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.MONTHLY_REPORT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleSearchMonthlyReport = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.MONTHLY_REPORT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// Yearly report
export const handleGetYearlyReport = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.YEARLY_REPORT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const handleSearchYearlyReport = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.YEARLY_REPORT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// Two date report
export const handleGetTwoDate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.REPORT_DATE_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// Two date report
export const handleGetHSNReport = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.HSN_REPORT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// Two date report
export const handleSearchHSNReport = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.HSN_REPORT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetStockDepletion = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(URL_CONSTANTS.STOCKDEP_REPORT_URL, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// REPORT API

// DASHBOARD API

export const handleDashboard = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.DASHBOARD_REPORT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// DASHBOARD API

// For handle product history
export const handleGetProductHistory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.PRODUCTHIST_REPORT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle product history data date wise
export const handleGetProductHistoryDate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.PRODUCTHISTDATE_REPORT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle customer history data
export const handleGetCustomerHistory = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.CUSTOMERHIST_REPORT_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle customer history data
export const handleGetCustomerHistory2 = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      URL_CONSTANTS.CUSTOMERHIST2_REPORT_URL,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle customer history data
export const handleGetCustomerHistoryDate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      URL_CONSTANTS.CUSTOMERHISTDATE_REPORT_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
