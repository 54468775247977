import React, { useCallback, useState, useEffect, useRef } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs.jsx"; // For breadcrumbs data import
import useStyles from "../../components/Styles/Style.jsx"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  ActionIcon,
  Modal,
  Loader,
  useMantineTheme,
  Grid,
  Select,
  Avatar,
  Anchor,
  Paper,
  FileButton,
  Badge,
} from "@mantine/core"; //For import mantine required functions and theme //
import {
  DotsCircleHorizontal,
  Pencil,
  Photo,
  Search,
  Trash,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { isEmail, isNotEmpty, useForm } from "@mantine/form"; // Mantine form import
import {
  handleGetProduct,
  handleGetCategory,
  handleAddProduct,
  handleEditProduct,
  handleGetTax,
  handleDeleteProduct,
  handleGetCustomerGroup,
  handleUploadProductImage,
  handleGetProductHistory,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../helpers/notifications"; // Import notification for this page
import { dataSearch, print } from "../../helpers/tableFunction"; // For table data functions
import { dataSlice, imageModal } from "../../helpers/common"; // Common functions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../assets/images/excel.png"; // Image for excel export
import pdf from "../../assets/images/pdf.png"; // Image for pdf exports
import { CSVLink } from "react-csv";
import { URL_CONSTANTS } from "../../constants/constants.js";
import { useDebounceEffect } from "../../common/userDebounceEffect.ts";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";
import GoldProducts from "../../helpers/GoldProducts.jsx";
// RichtextEditor
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Product() {
  // Mantine custome style use
  const { classes } = useStyles();
  const modals = useModals();
  const theme = useMantineTheme();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [sortedData2, setSortedData2] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [loadingData, setLoadingData] = useState(false);
  const [sortBy, setSortBy] = useState(null); // Setting the sortby table type
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [category, setCategory] = useState([]); // For table data
  const [hsn, setHsn] = useState([]); // For table data
  const [tax, setTax] = useState([]); // For table data
  const [group, setGroup] = useState([]); // For table data

  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  // Product golden
  const [productModal, setProductModal] = useState(false);
  const [goldTitle, setGoldTitle] = useState("");
  const [goldValue, setGoldValue] = useState("");
  const [goldData, setGoldData] = useState("");

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    skuDrawer: false,
    openEdit: false,
    imageDrawer: false,
    deleteIndex: 0,
  });

  // For form validation
  const form = useForm({
    initialValues: {
      details: [
        {
          custGroup_id: "1",
          sku: "",
        },
      ],
      product: "",
      category_id: 1,
      hsn_code: "",
      sku_code: "",
      quantity: "",
      price: "",
      unit: "Pcs",
      stock_depletion: "",
    },
    validate: {
      product: isNotEmpty("Product name cannot be empty"),
    },
  });

  //   For edit form data validation
  const formEdit = useForm({
    initialValues: {
      editDetails: [
        {
          custGroup_id: "1",
          sku: "",
        },
      ],
      value: "",
      product: "",
      category_id: 1,
      hsn_code: "",
      sku_code: "",
      tax_id: "",
      quantity: "",
      price: "",
      unit: "",
      stock_depletion: "",
    },
    validate: {
      product: isNotEmpty("Product name cannot be empty"),
    },
  });

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetProduct();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: activePage,
            total: total,
          });
          setSortedData(datas);
          setSortedData2(response.data.data.reverse());
        }

        const response1 = await handleGetCategory();

        if (response1.status === 200) {
          var data = response1.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.label.toString(),
          }));
          setCategory(clean);
        }

        const response3 = await handleGetTax();

        if (response3.status === 200) {
          var data = response3.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.label.toString(),
          }));
          setTax(clean);
        }

        const response4 = await handleGetCustomerGroup();

        if (response4.status === 200) {
          var data = response4.data.data.reverse();
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.label.toString(),
          }));
          setGroup(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.PRODUCT_IMAGE_URL + row.images,
                  title: row.label,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.PRODUCT_IMAGE_URL + row.images}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.PRODUCT_IMAGE_URL + row.images,
                  title: row.label,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.PRODUCT_IMAGE_URL + row.images}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={async () => {
                  const data = await handleGetProductHistory(row.value);
                  const list = data.data.data;
                  setProductModal(true);
                  setGoldData(list);
                  setGoldTitle(row.label);
                  setGoldValue(row.value);
                }}
                component="button"
                size="sm"
              >
                {row.label}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              category: {row.category.label}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Group>
          <ActionIcon
            onClick={async () => {
              const data = await handleGetProductHistory(row.value);
              const list = data.data.data;
              setProductModal(true);
              setGoldData(list);
              setGoldTitle(row.label);
              setGoldValue(row.value);
            }}
            size="md"
            color="zevcore"
          >
            <DotsCircleHorizontal size={20} />
          </ActionIcon>
        </Group>
      </td>
      <td>{row.hsn_code}</td>
      <td>{row.sku_code}</td>
      <td>{row.tax.label}</td>
      <td>{row.quantity}</td>
      <td>
        {row.quantity < row.stock_depletion ? (
          <Badge color="yellow">Running Out Of Stock</Badge>
        ) : row.quantity === 0 ? (
          <Badge color="red">Out Of Stock</Badge>
        ) : (
          <Badge color="green">In Stock</Badge>
        )}
      </td>
      <td>{row.price}</td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => openUpload(row.value)}>
            <Photo color="#043c64" size={18} />
          </ActionIcon>
          <ActionIcon onClick={() => handleEdit(row.value)}>
            <Pencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.value)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Add new customer_group data
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddProduct(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Product added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Editing or the setting the customer_group value
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    datas.sku_lists = datas.sku_lists.map((data) => ({
      ...data,
      sku: data.label.toString(),
      custGroup_id: data.group_id.toString(),
      product_id: data.product_id.toString(),
    }));
    formEdit.setFieldValue("editDetails", datas.sku_lists);
    formEdit.setFieldValue("value", datas.value);
    formEdit.setFieldValue("product", datas.label);
    formEdit.setFieldValue("category_id", datas.category.value.toString());
    formEdit.setFieldValue("hsn_code", datas.hsn_code);
    formEdit.setFieldValue("sku_code", datas.sku_code);
    formEdit.setFieldValue("tax_id", datas.tax.value.toString());
    formEdit.setFieldValue("quantity", datas.quantity);
    formEdit.setFieldValue("price", datas.price);
    formEdit.setFieldValue("unit", datas.unit);
    formEdit.setFieldValue("stock_depletion", datas.stock_depletion);
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Edit function for updating customer_group values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditProduct(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the product <b>{datas.label}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteProduct(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // For po add more adding extra row data for po
  const fields = form.values.details.map((_, index) => (
    <tr key={index}>
      <td>
        <Text>{index + 1}</Text>
      </td>
      <td>
        <Select
          variant="filled"
          classNames={{ item: classes.selectItem }}
          searchable
          required
          size="xs"
          placeholder="Select Customer Group"
          data={group}
          {...form.getInputProps(`details.${index}.custGroup_id`)}
        />
      </td>
      <td>
        <TextInput
          variant="filled"
          mr={20}
          required
          size="xs"
          placeholder="SKU"
          {...form.getInputProps(`details.${index}.sku`)}
        />
      </td>
      <td>
        <ActionIcon
          color="red"
          mr={20}
          variant="hover"
          onClick={() => form.removeListItem("details", index)}
        >
          <Trash color="red" size={16} />
        </ActionIcon>
      </td>
    </tr>
  ));

  const fieldEdits = formEdit.values.editDetails.map((_, index) => (
    <tr key={index}>
      <td>
        <Text>{index + 1}</Text>
      </td>
      <td>
        <Select
          variant="filled"
          classNames={{ item: classes.selectItem }}
          searchable
          required
          size="xs"
          placeholder="Select Customer Group"
          data={group}
          {...formEdit.getInputProps(`editDetails.${index}.custGroup_id`)}
        />
      </td>
      <td>
        <TextInput
          variant="filled"
          mr={20}
          required
          size="xs"
          placeholder="SKU"
          {...formEdit.getInputProps(`editDetails.${index}.sku`)}
        />
      </td>
      <td>
        <ActionIcon
          color="red"
          mr={20}
          variant="hover"
          onClick={() => formEdit.removeListItem("editDetails", index)}
        >
          <Trash color="red" size={16} />
        </ActionIcon>
      </td>
    </tr>
  ));

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data.find((img) => img.value === e);
    console.log(datas);
    setImageTitle(datas.label);
    SetProfileimage(datas.image);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // upload admin image values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: id_value,
    };
    e = { ...e, ...req };
    const response = await handleUploadProductImage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Product image uploaded successfully",
      });
      setUpImg("");
      setImage("");
      setVariables({
        ...variables,
        submitLoading: false,
        imageDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Products" />
      </Skeleton>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <div>
                <TextInput
                  variant="filled"
                  placeholder="Search"
                  mb="md"
                  size="sm"
                  mr={10}
                  style={{ float: "left" }}
                  value={search}
                  icon={<Search size={16} />}
                  onChange={async (e) => {
                    // On change search ofr the data that is enter
                    setSearch(e.currentTarget.value);
                    // setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setSortedData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <Select
                  placeholder="Select Category"
                  data={category}
                  searchable
                  mr={10}
                  style={{ float: "left" }}
                  clearable
                  onChange={async (e) => {
                    // On change search for the data that is enter
                    setPage(1);
                    var datas = sortedData2;
                    var filter = "";
                    var date2 = "";
                    if (e != "") {
                      datas = datas.filter(function (item) {
                        if (Number(e) == Number(item.category_id)) {
                          return true;
                        }
                        return false;
                      });
                      console.log(datas);
                    } else {
                      return true;
                    }
                    setVariables((variables) => {
                      return {
                        ...variables,
                        data: datas,
                        skeletonLoading: false,
                      };
                    });
                    const datas2 = dataSlice({
                      data: datas,
                      page: activePage,
                      total: total,
                    });
                    setSortedData(datas2);
                    setRefreshTable(new Date());
                  }}
                />
                <Button
                  loading={variables.submitLoading}
                  onClick={() => {
                    setCategory([]);
                    setSearch("");
                    setVariables((variables) => {
                      return {
                        ...variables,
                        data: sortedData2,
                        skeletonLoading: false,
                      };
                    });
                    const datas2 = dataSlice({
                      data: sortedData2,
                      page: 1,
                      total: 10,
                    });
                    setSortedData(datas2);
                  }}
                  ml={10}
                  style={{ float: "left" }}
                  color="zevcore"
                >
                  Clear
                </Button>
              </div>
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "value", key: "value" },
                      { label: "image", key: "image" },
                      { label: "label", key: "label" },
                      { label: "category_id", key: "category_id" },
                      { label: "hsn_code", key: "hsn_code" },
                      { label: "sku_code", key: "sku_code" },
                      { label: "tax_id", key: "tax_id" },
                      { label: "price", key: "price" },
                      { label: "stock_depletion", key: "stock_depletion" },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="products.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>

                  {/* Drawer open for adding new category data */}
                  <Button
                    variant="outline"
                    color="zevcore"
                    size="xs"
                    onClick={() =>
                      setVariables({ ...variables, addDrawer: true })
                    }
                  >
                    + Add Product
                  </Button>
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Product Name</th>
                  <th>Explore</th>
                  <th>HSN/ SAC Code</th>
                  <th>SKU Code</th>
                  <th>GST (%)</th>
                  <th>Available Quantity</th>
                  <th>Stock Status</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === true ? (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#043c64" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="green">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
      </Skeleton>

      {/* Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Product"}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
              <Grid>
                <Grid.Col md={3} lg={3}>
                  <Select
                    label="Select Category"
                    placeholder="Select Category"
                    data={category}
                    searchable
                    required
                    clearable
                    {...form.getInputProps("category_id")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Product Name"
                    withAsterisk
                    required
                    value={form.values.product}
                    placeholder="Product Name"
                    {...form.getInputProps("product")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="HSN Code"
                    withAsterisk
                    required
                    value={form.values.hsn_code}
                    placeholder="HSN Code"
                    {...form.getInputProps("hsn_code")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="SKU Code"
                    withAsterisk
                    required
                    value={form.values.sku_code}
                    placeholder="SKU Code"
                    {...form.getInputProps("sku_code")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <Select
                    label="Select Tax"
                    placeholder="Select Tax"
                    data={tax}
                    searchable
                    required
                    clearable
                    {...form.getInputProps("tax_id")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Quantity"
                    withAsterisk
                    required
                    value={form.values.quantity}
                    placeholder="Quantity"
                    {...form.getInputProps("quantity")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Unit"
                    withAsterisk
                    required
                    value={form.values.unit}
                    placeholder="Unit"
                    {...form.getInputProps("unit")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Price"
                    withAsterisk
                    required
                    value={form.values.price}
                    placeholder="Price"
                    {...form.getInputProps("price")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <TextInput
                    label="Stock Depletion"
                    value={form.values.stock_depletion}
                    placeholder="Stock Depletion"
                    {...form.getInputProps("stock_depletion")}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col md={6} lg={6}>
                  <Group position="left" mt={10}>
                    <Text align="left" mb="sm" size="md">
                      Add SKU for respective customer group..
                    </Text>
                  </Group>
                </Grid.Col>
                <Grid.Col md={6} lg={6}>
                  <Group position="right" mt={10}>
                    {/* On click more adding extra row here */}
                    <Button
                      color="zevcore"
                      size="xs"
                      mb="sm"
                      onClick={() =>
                        form.insertListItem("details", {
                          custGroup_id: "1",
                          sku: "",
                        })
                      }
                    >
                      Add More SKU
                    </Button>
                  </Group>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <ScrollArea
                    className="add_more_scroll"
                    scrollbarSize={10}
                    color="zevcore"
                    style={{
                      height: "40vh",
                    }}
                  >
                    {fields.length > 0 ? (
                      <>
                        <Table
                          horizontalSpacing="md"
                          verticalSpacing="xs"
                          className={classes.striped}
                        >
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Customer Group</th>
                              <th>SKU</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody key={refreshTable}>{fields}</tbody>
                        </Table>
                      </>
                    ) : null}
                  </ScrollArea>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    color="zevcore"
                  >
                    Add Product
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Modal */}

      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Product Details"}
        size="70%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={formEdit.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col md={3} lg={3}>
                <Select
                  label="Select Category"
                  placeholder="Select Category"
                  data={category}
                  searchable
                  required
                  clearable
                  {...formEdit.getInputProps("category_id")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Product Name"
                  withAsterisk
                  value={formEdit.values.product}
                  placeholder="Product Name"
                  {...formEdit.getInputProps("product")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="HSN Code"
                  withAsterisk
                  required
                  value={formEdit.values.hsn_code}
                  placeholder="HSN Code"
                  {...formEdit.getInputProps("hsn_code")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="SKU Code"
                  withAsterisk
                  required
                  value={formEdit.values.sku_code}
                  placeholder="SKU Code"
                  {...formEdit.getInputProps("sku_code")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Select
                  label="Select Tax"
                  placeholder="Select Tax"
                  data={tax}
                  searchable
                  required
                  clearable
                  {...formEdit.getInputProps("tax_id")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Quantity"
                  withAsterisk
                  required
                  value={formEdit.values.quantity}
                  placeholder="Quantity"
                  {...formEdit.getInputProps("quantity")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Unit"
                  withAsterisk
                  required
                  value={formEdit.values.unit}
                  placeholder="Unit"
                  {...formEdit.getInputProps("unit")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Price"
                  withAsterisk
                  required
                  value={formEdit.values.price}
                  placeholder="Price"
                  {...formEdit.getInputProps("price")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Stock Depletion"
                  value={formEdit.values.stock_depletion}
                  placeholder="Stock Depletion"
                  {...formEdit.getInputProps("stock_depletion")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={6} lg={6}>
                <Group position="left" mt={10}>
                  <Text align="left" mb="sm" size="md">
                    Update SKU for respective customer group..
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Group position="right" mt={10}>
                  {/* On click more adding extra row here */}
                  <Button
                    color="zevcore"
                    size="xs"
                    mb="sm"
                    onClick={() =>
                      formEdit.insertListItem("editDetails", {
                        custGroup_id: "1",
                        sku: "",
                      })
                    }
                  >
                    Add More SKU
                  </Button>
                </Group>
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <ScrollArea
                  className="add_more_scroll"
                  scrollbarSize={10}
                  color="zevcore"
                  style={{
                    height: "40vh",
                  }}
                >
                  {fieldEdits.length > 0 ? (
                    <>
                      <Table
                        horizontalSpacing="md"
                        verticalSpacing="xs"
                        className={classes.striped}
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Customer Group</th>
                            <th>SKU</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody key={refreshTable}>{fieldEdits}</tbody>
                      </Table>
                    </>
                  ) : null}
                </ScrollArea>
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Button
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  color="zevcore"
                >
                  Update Product
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Edit Modal */}

      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={imageTitle}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* <Text>Upload State Image</Text> */}
            </Grid.Col>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image cropper */}
              {/* For cropper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={URL_CONSTANTS.PRODUCT_IMAGE_URL + profileimage}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />
                    <Text ta="center" fz="lg" weight={500} mt="md">
                      {imageTitle}
                    </Text>

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="sm"
              variant="outline"
              color="zevcore"
            >
              Update Image
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}
      <GoldProducts
        value={goldValue}
        title={goldTitle}
        list={goldData}
        productModal={productModal}
        setProductModal={setProductModal}
      />
    </div>
  );
}
export default Product;
