import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs.jsx"; // For breadcrumbs data import
import useStyles from "../../components/Styles/Style.jsx"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  ActionIcon,
  Modal,
  Loader,
  useMantineTheme,
  Grid,
  Badge,
  Avatar,
  Anchor,
  Select,
} from "@mantine/core"; //For import mantine required functions and theme //
import {
  Pencil,
  Search,
  Trash,
  Print,
  Printer,
  DotsCircleHorizontal,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import {
  handleDeleteInvoice,
  handleGetCustomerHistory,
  handleGetInvoice,
} from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../helpers/notifications"; // Import notification for this page
import { dataSearch, print } from "../../helpers/tableFunction"; // For table data functions
import { dataSlice } from "../../helpers/common"; // Common functions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../assets/images/excel.png"; // Image for excel export
import pdf from "../../assets/images/pdf.png"; // Image for pdf exports
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../../constants/constants.js";
import GoldCustomers from "../../helpers/GoldCustomers.jsx";

function ViewSales() {
  // Mantine custome style use
  const { classes } = useStyles();
  const modals = useModals();
  let navigate = useNavigate();
  const theme = useMantineTheme();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [sortedData2, setSortedData2] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [loadingData, setLoadingData] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [sortBy, setSortBy] = useState(null); // Setting the sortby table type
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [customerModal, setCustomerModal] = useState(false);
  const [goldTitle, setGoldTitle] = useState("");
  const [goldValue, setGoldValue] = useState("");
  const [goldData, setGoldData] = useState("");

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetInvoice();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
          setSortedData2(datas);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.customer.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.CUSTOMER_IMAGE + row.customer.image,
                  title: row.customer.label,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.CUSTOMER_IMAGE + row.customer.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: URL_CONSTANTS.CUSTOMER_IMAGE + row.customer.image,
                  title: row.customer.label,
                });
              }}
              radius={30}
              size={30}
              src={URL_CONSTANTS.CUSTOMER_IMAGE + row.customer.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={async () => {
                  const data = await handleGetCustomerHistory(
                    row.customer.value
                  );
                  const list = data.data.data;
                  setCustomerModal(true);
                  setGoldData(list);
                  setGoldTitle(row.customer.label);
                  setGoldValue(row.customer.value);
                }}
                component="button"
                size="sm"
              >
                {row.customer.label}
              </Anchor>
            </Text>
            <Text c="dimmed" fz="xs">
              Customer Group: {row.customer.group.label}
            </Text>
          </div>
        </Group>
      </td>
      <td>{row.customer.gstin !== null ? row.customer.gstin : "--"}</td>
      <td>{new Date(row.invoice_date).toLocaleDateString("en-IN")}</td>
      <td>{row.invoice_number}</td>
      <td>
        {row.invoice_type === "Inclusive Tax" ? (
          <Badge color="green">GST Inclusive</Badge>
        ) : (
          <Badge color="yellow">GST Exclusive</Badge>
        )}
      </td>
      <td>{formatter.format(row.final_amount - row.discount_amount)}</td>
      <td>
        {row.payment_status === "Completed" ? (
          <Badge color="green">{row.payment_status}</Badge>
        ) : (
          <Badge color="red">{row.payment_status}</Badge>
        )}
      </td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          {row.invoice_type === "Inclusive Tax" ? (
            <ActionIcon onClick={() => navigate("/edit_sales/" + row.value)}>
              <Pencil size={18} color="gray" />
            </ActionIcon>
          ) : (
            <ActionIcon
              onClick={() => navigate("/edit_exclusive_sales/" + row.value)}
            >
              <Pencil size={18} color="gray" />
            </ActionIcon>
          )}
          {row.customer.state === "Karnataka" ? (
            <ActionIcon onClick={() => navigate("/print_invoice/" + row.value)}>
              <Printer size={18} color="gray" />
            </ActionIcon>
          ) : (
            <ActionIcon
              onClick={() => navigate("/print_invoice1/" + row.value)}
            >
              <Printer size={18} color="gray" />
            </ActionIcon>
          )}
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.value)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  const handleEdit = (e) => {};

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the invoice{" "}
          <b>{datas.invoice_number}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteInvoice(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Invoice deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="View Invoices" />
      </Skeleton>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <div>
                <TextInput
                  variant="filled"
                  placeholder="Search"
                  mb="md"
                  size="sm"
                  mr={10}
                  style={{ float: "left" }}
                  value={search}
                  icon={<Search size={16} />}
                  onChange={async (e) => {
                    // On change search ofr the data that is enter
                    setSearch(e.currentTarget.value);
                    setPage(1);
                    const datas = await dataSearch({
                      data: variables.data,
                      value: e.currentTarget.value,
                      activePage: activePage,
                      total: total,
                    });
                    setSortedData(datas);
                    setRefreshTable(new Date());
                  }}
                  sx={{ width: 250 }}
                />
                <Select
                  placeholder="Select Invoice Type"
                  data={[
                    { value: "Inclusive Tax", label: "Inclusive Tax" },
                    { value: "Exclusive Tax", label: "Exclusive Tax" },
                  ]}
                  searchable
                  style={{ float: "left" }}
                  clearable
                  onChange={async (e) => {
                    // On change search for the data that is enter
                    setPage(1);
                    var datas = sortedData2;
                    var filter = "";
                    var date2 = "";
                    if (e != "") {
                      datas = datas.filter(function (item) {
                        if (e == item.invoice_type) {
                          return true;
                        }
                        return false;
                      });
                    } else {
                      return true;
                    }
                    setVariables((variables) => {
                      return {
                        ...variables,
                        data: datas,
                        skeletonLoading: false,
                      };
                    });
                    const datas2 = dataSlice({
                      data: datas,
                      page: activePage,
                      total: total,
                    });
                    setSortedData(datas2);
                    setRefreshTable(new Date());
                  }}
                />
                <Button
                  onClick={() => {
                    setLoadingState(true);
                    setSearch("");
                    setVariables((variables) => {
                      return {
                        ...variables,
                        data: sortedData2,
                        skeletonLoading: false,
                      };
                    });
                    const datas2 = dataSlice({
                      data: sortedData2,
                      page: 1,
                      total: 10,
                    });
                    setLoadingState(false);
                    setSortedData(datas2);
                  }}
                  ml={10}
                  style={{ float: "left" }}
                  color="zevcore"
                >
                  Clear
                </Button>
              </div>
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "value", key: "value" },
                      { label: "prefix_number", key: "prefix_number" },
                      { label: "invoice_number", key: "invoice_number" },
                      { label: "customer_id", key: "customer_id" },
                      { label: "customer_po", key: "customer_po" },
                      { label: "invoice_type", key: "invoice_type" },
                      { label: "final_amount", key: "final_amount" },
                      { label: "discount_amount", key: "discount_amount" },
                      { label: "after_discount", key: "after_discount" },
                      { label: "due_amount", key: "due_amount" },
                      { label: "status", key: "status" },
                      { label: "payment_status", key: "payment_status" },
                      { label: "email_status", key: "email_status" },
                      { label: "due_date", key: "due_date" },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="invoice.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  <Button
                    variant="outline"
                    color="green"
                    size="xs"
                    onClick={() => navigate("/add_inclusive_sales/")}
                  >
                    + Add GST Inclusive
                  </Button>
                  <Button
                    variant="outline"
                    color="yellow"
                    size="xs"
                    onClick={() => navigate("/add_exclusive_sales/")}
                  >
                    + Add GST Exclusive
                  </Button>
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Customer Name</th>
                  <th>Customer GST</th>
                  <th>Invoice Date</th>
                  <th>Invoice Number</th>
                  <th>Invoice Type</th>
                  <th>Total Amount</th>
                  <th>Payment Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === true ? (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#043c64" variant="dots" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="green">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
      </Skeleton>
      <GoldCustomers
        value={goldValue}
        title={goldTitle}
        list={goldData}
        customerModal={customerModal}
        setCustomerModal={setCustomerModal}
      />
    </div>
  );
}
export default ViewSales;
