import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs";
// Mantine library
import { X, Check } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  Space,
  Card,
  Button,
  NumberInput,
  TextInput,
  Skeleton,
  PasswordInput,
  Grid,
} from "@mantine/core";
// For image Crop
import "react-image-crop/dist/ReactCrop.css";
import { handleEditUser, handleGetUserOne } from "../../helpers/apis";

function Profile() {
  const [token, setToken] = useState(localStorage.getItem("token")); //get saved local storage data
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [URL, setURL] = useState(process.env.REACT_APP_BACKEND_URL);
  const [URLFILE, setURLFILE] = useState(process.env.REACT_APP_FILE);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [id, setId] = useState("");
  const form = useForm({
    initialValues: {
      value: 1,
      name: "",
      email: "",
      phone_number: "",
      password: "",
      image: "profile.png",
      confirmPassword: "",
    },
    validate: {
      name: (value) =>
        value.length < 5 ? "Name must have at least 5 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      phone_number: (value) =>
        value < 10 ? "phone number must be at least 10 to register" : null,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await handleGetUserOne();
      if (response.status == 200) {
        var datas = response.data;
        form.setFieldValue("name", datas.label);
        form.setFieldValue("email", datas.email);
        form.setFieldValue("phone_number", Number(datas.phone_number));
        setId(datas.value);
        setSkeletonLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleAdd = async (e) => {
    console.log(e);
    // Set notification of saving and loader effects
    setSubmitLoading(true);
    showNotification({
      loading: true,
      id: "load-data",
      title: `Saving...`,
      message: "Waiting for response",
      autoclose: 5000,
      style: { borderRadius: 10 },
    });

    // Main axios part for sending data to backend adding user data
    const response = await handleEditUser(e);

    if (response.status == 200) {
      setSubmitLoading(false);
      updateNotification({
        id: "load-data",
        color: "teal",
        title: "Data Save",
        message: "User Field Updated Successfully",
        icon: <Check />,
      });
    } else {
      setSubmitLoading(false);
      updateNotification({
        id: "load-data",
        color: "red",
        title: "Data Save Error",
        message: "Some error accur",
        icon: <X />,
      });
    }
  };

  return (
    <div>
      <Skeleton
        height="100%"
        width="100%"
        radius="md"
        visible={skeletonLoading}
      >
        <BreadCrumb Text="Profile" Title="Settings" titleLink="/manages" />
      </Skeleton>
      <Space h="md" />
      {/* Main page start from here */}
      <Skeleton
        height="100%"
        width="100%"
        radius="md"
        sx={(theme) => ({
          boxShadow:
            "0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 10px 15px -5px, rgb(0 0 0 / 4%) 0px 7px 7px -5px",
        })}
        visible={skeletonLoading}
      >
        <Card p="lg">
          <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
            <Grid>
              <Grid.Col md={12} lg={12}>
                <Grid>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      variant="filled"
                      mt={2}
                      required
                      value={form.values.name}
                      label=" Name"
                      placeholder="Enter Name"
                      {...form.getInputProps("name")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      variant="filled"
                      required
                      value={form.values.email}
                      label=" Email"
                      placeholder="Enter Email"
                      {...form.getInputProps("email")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <NumberInput
                      variant="filled"
                      required
                      maxLength={10}
                      value={form.values.phone_number}
                      label=" Phone Number"
                      placeholder="Enter Phone Number"
                      {...form.getInputProps("phone_number")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <PasswordInput
                      value={form.values.password}
                      label=" Password"
                      placeholder="Enter Password"
                      {...form.getInputProps("password")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <PasswordInput
                      value={form.values.confirmPassword}
                      label="Confirm Password"
                      placeholder=" Confirm Password"
                      {...form.getInputProps("confirmPassword")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Button
                      type="submit"
                      color="zevcore"
                      loading={submitLoading}
                    >
                      Submit
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>
    </div>
  );
}

export default Profile;
