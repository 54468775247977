/**

    * Application Name: Imprulife Venture Pvt Ltd Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 03.04.2023
    * Last Modified Date: 03.04.2023
    * Developer Name: Anand_Krishna

**/

const BASE_URL = `https://backend.imprulife.com/`;
// const BASE_URL = `http://192.168.1.84:3008/`;
const API_URL = BASE_URL + "api";

export const URL_CONSTANTS = {
  LOGIN_URL: `${API_URL}/auth/signin`,
  LOGO_URL: `${BASE_URL}public/images/logo/`,
  DASHBOARD_REPORT_URL: `${API_URL}/dashboardReport`,
  STOCKDEP_REPORT_URL: `${API_URL}/stockdepReport`,
  USER_ONE_URL: `${API_URL}/user_one`,
  USER_URL: `${API_URL}/user`,
  TAX_URL: `${API_URL}/tax`,
  HSN_URL: `${API_URL}/hsn`,
  BANK_URL: `${API_URL}/bank`,
  PREFIX_URL: `${API_URL}/prefix`,
  PREFIX_UPLOAD_URL: `${API_URL}/prefix_image`,
  CUSTOMER_GROUP_URL: `${API_URL}/group`,
  CUSTOMER_URL: `${API_URL}/customer`,
  CUSTOMER_UPLOAD_URL: `${API_URL}/customer_image`,
  CUSTOMER_IMAGE: `${BASE_URL}public/images/customer/`,
  CATEGORY_URL: `${API_URL}/category`,
  PRODUCT_URL: `${API_URL}/product`,
  PRODUCT_UPLOAD_URL: `${API_URL}/product_image`,
  PRODUCT_IMAGE_URL: `${BASE_URL}public/images/products/`,
  PRODUCTHIST_REPORT_URL: `${API_URL}/productHistReport`,
  REMINDER_URL: `${API_URL}/reminder`,
  WEEKLY_REPORT_URL: `${API_URL}/weekly_report`,
  MONTHLY_REPORT_URL: `${API_URL}/monthly_report`,
  YEARLY_REPORT_URL: `${API_URL}/yearly_report`,
  HSN_REPORT_URL: `${API_URL}/hsnReport`,
  REPORT_DATE_URL: `${API_URL}/reportDate`,
  INVOICE_URL: `${API_URL}/invoice`,
  INVOICE_NOTES_URL: `${API_URL}/invoice_notes`,
  INVOICE_TRANSACTION_DELETE_URL: `${API_URL}/invoice_transactions`,
  SAVE_INVOICE_URL: `${API_URL}/save_invoice`,
  PRODUCTHISTDATE_REPORT_URL: `${API_URL}/productHistDateReport`,
  CUSTOMERHIST_REPORT_URL: `${API_URL}/customerHistoryReport`,
  CUSTOMERHIST2_REPORT_URL: `${API_URL}/customerHistoryReport2`,
  CUSTOMERHISTDATE_REPORT_URL: `${API_URL}/customerHistoryDateReport`,
};
