/**

    * Application Name: Imprulife Venture Pvt Ltd Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 03.04.2023
    * Last Modified Date: 03.04.2023
    * Developer Name: Anand_Krishna

**/
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Notifications } from "@mantine/notifications";
import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";

// Components
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import ErrorBoundary from "./components/Error/Error";
import Sidebar from "./components/Sidebar/Sidebar";

// Authentication
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";

// Dashboard
import Dashboard from "./pages/Dashboard";
// Settings
import Manage from "./pages/settings/Manage";
import Bank from "./pages/settings/Bank";
import Profile from "./pages/settings/Profile";
import Tax from "./pages/settings/Tax";
import Hsn_list from "./pages/settings/Hsn_list";
import Prefix from "./pages/settings/Prefix";

// Customer
import Customer from "./pages/customers/Customers";
import CustomerGroup from "./pages/customers/CustomerGroup";

// Product
import Product from "./pages/product/Product";
import Category from "./pages/product/Category";

// Report
import Weekly from "./pages/report/Weekly";
import Monthly from "./pages/report/Monthly";
import Yearly from "./pages/report/Yearly";
import HSN from "./pages/report/HSN";
import GstReport from "./pages/report/GstReport";

// Invoice
import AddSales from "./pages/sales/AddSales";
import EditSales from "./pages/sales/EditSales";
import AddExclusiveSales from "./pages/sales/AddExclusiveSales";
import EditExclusiveSales from "./pages/sales/EditExclusiveSales";
import ViewSales from "./pages/sales/ViewSales";
import PrintSales from "./pages/sales/PrintSales";
import PrintDemo from "./pages/sales/PrintDemo";

function App() {
  // For get the dark light toggle
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  // Get the last mode
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  // Change the color if u want to '#043c64' Carefully wile choosing dark color only
  const [color] = useLocalStorage({
    key: "color",
    defaultValue: "#043c64",
  });

  // For make color lighter by 10 colors level
  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  };
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        withCSSVariables
        theme={{
          colors: {
            zevcore: [
              LightenDarkenColor(color, 140),
              LightenDarkenColor(color, 130),
              LightenDarkenColor(color, 120),
              LightenDarkenColor(color, 110),
              LightenDarkenColor(color, 100),
              LightenDarkenColor(color, 90),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 80),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 20),
            ],
          },
          primaryColor: "zevcore",
          "::-webkit-scrollbar": {
            backgroundColor: LightenDarkenColor(color, 120),
            width: "5px",
            height: "10px",
            borderRadius: 5,
            fontFamily: "Poppins",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: LightenDarkenColor(color, 120),
            borderRadius: 5,
            // "#D50000"
          },
          fontFamily: "Poppins",
          fontFamilyMonospace: "Poppins",
          headings: { fontFamily: "Poppins" },
          fontWeight: 200,
          colorScheme,
        }}
      >
        <Notifications position="top-right" autoClose={3000} />
        <ErrorBoundary>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<Sidebar />}>
              <Route
                exact
                path="/"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/manages"
                element={
                  <ProtectedRoute>
                    <Manage />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/bank"
                element={
                  <ProtectedRoute>
                    <Bank />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/account"
                element={
                  <ProtectedRoute>
                    <Prefix />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/tax"
                element={
                  <ProtectedRoute>
                    <Tax />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/hsn_list"
                element={
                  <ProtectedRoute>
                    <Hsn_list />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/products"
                element={
                  <ProtectedRoute>
                    <Product />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/category"
                element={
                  <ProtectedRoute>
                    <Category />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/customers"
                element={
                  <ProtectedRoute>
                    <Customer />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/groups"
                element={
                  <ProtectedRoute>
                    <CustomerGroup />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/add_inclusive_sales"
                element={
                  <ProtectedRoute>
                    <AddSales />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/add_exclusive_sales"
                element={
                  <ProtectedRoute>
                    <AddExclusiveSales />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/edit_sales/:value"
                element={
                  <ProtectedRoute>
                    <EditSales />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/edit_exclusive_sales/:value"
                element={
                  <ProtectedRoute>
                    <EditExclusiveSales />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/view_sales"
                element={
                  <ProtectedRoute>
                    <ViewSales />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/print_invoice/:value"
                element={
                  <ProtectedRoute>
                    <PrintSales />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/print_invoice1/:value"
                element={
                  <ProtectedRoute>
                    <PrintDemo />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/weekly_sales"
                element={
                  <ProtectedRoute>
                    <Weekly />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/monthly_sales"
                element={
                  <ProtectedRoute>
                    <Monthly />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/yearly_sales"
                element={
                  <ProtectedRoute>
                    <Yearly />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/hsn_report"
                element={
                  <ProtectedRoute>
                    <HSN />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/gst_report"
                element={
                  <ProtectedRoute>
                    <GstReport />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
        </ErrorBoundary>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
