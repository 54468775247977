import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Breadcrumbs/BreadCrumbs.jsx"; // For breadcrumbs data import
import useStyles from "../../components/Styles/Style.jsx"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Button,
  useMantineTheme,
  Grid,
  Select,
} from "@mantine/core"; //For import mantine required functions and theme //
import { useForm } from "@mantine/form"; // Mantine form import
import { handleEditBank, handleGetBankOne } from "../../helpers/apis"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../helpers/notifications"; // Import notification for this page

function Bank() {
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    deleteIndex: 0,
    loadingData: false,
  });

  //   For edit form data validation
  const formEdit = useForm({
    initialValues: {
      value: 1,
      account_name: "",
      bank_name: "",
      account_number: "",
      ifsc_code: "",
      swift_code: "",
      branch_name: "",
      address: "",
      status: "",
    },
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetBankOne();
        // On Response setting the data to variable
        if (response.status == 200) {
          var datas = response.data;
          formEdit.setFieldValue("value", datas.value);
          formEdit.setFieldValue("account_name", datas.label);
          formEdit.setFieldValue("bank_name", datas.bank_name);
          formEdit.setFieldValue("branch_name", datas.branch);
          formEdit.setFieldValue("account_number", datas.account_no);
          formEdit.setFieldValue("ifsc_code", datas.ifsc_code);
          formEdit.setFieldValue("swift_code", datas.swift_code);
          formEdit.setFieldValue("address", datas.address);
          formEdit.setFieldValue("status", datas.status);
          setVariables({
            ...variables,
            submitLoading: false,
            skeletonLoading: false,
          });
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Edit function for updating designation values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true, loadingData: true });
    const response = await handleEditBank(e);
    console.log(response.data.data[0]);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Bank details updated successfully",
      });
      var datas = response.data.data[0];
      formEdit.setFieldValue("value", datas.value);
      formEdit.setFieldValue("account_name", datas.label);
      formEdit.setFieldValue("bank_name", datas.bank_name);
      formEdit.setFieldValue("branch_name", datas.branch);
      formEdit.setFieldValue("account_number", datas.account_no);
      formEdit.setFieldValue("ifsc_code", datas.ifsc_code);
      formEdit.setFieldValue("swift_code", datas.swift_code);
      formEdit.setFieldValue("address", datas.address);
      formEdit.setFieldValue("status", datas.status);
      setVariables({ ...variables, submitLoading: false, loadingData: false });
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, loadingData: false });
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Bank Settings"
          Title="Settings"
          titleLink="/manages"
        />
      </Skeleton>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card shadow="sm" p="lg" className="border">
          <form onSubmit={formEdit.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Account Name"
                  withAsterisk
                  required
                  value={formEdit.values.account_name}
                  placeholder="Account Name"
                  {...formEdit.getInputProps("account_name")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Bank Name"
                  withAsterisk
                  required
                  value={formEdit.values.bank_name}
                  placeholder="Bank Name"
                  {...formEdit.getInputProps("bank_name")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Account Number"
                  withAsterisk
                  required
                  value={formEdit.values.account_number}
                  placeholder="Account Number"
                  {...formEdit.getInputProps("account_number")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Branch Name"
                  withAsterisk
                  required
                  value={formEdit.values.branch_name}
                  placeholder="Branch Name"
                  {...formEdit.getInputProps("branch_name")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="IFSC Code"
                  withAsterisk
                  required
                  value={formEdit.values.ifsc_code}
                  placeholder="IFSC Code"
                  {...formEdit.getInputProps("ifsc_code")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <TextInput
                  label="Swift Code"
                  withAsterisk
                  required
                  value={formEdit.values.swift_code}
                  placeholder="Swift Code"
                  {...formEdit.getInputProps("swift_code")}
                />
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Select
                  label="Select Status"
                  placeholder="Select Status"
                  withAsterisk
                  required
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Block", label: "Block" },
                  ]}
                  value={formEdit.values.status}
                  {...formEdit.getInputProps("status")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <TextInput
                  label="Address"
                  withAsterisk
                  required
                  value={formEdit.values.address}
                  placeholder="Address"
                  {...formEdit.getInputProps("address")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Button
                  loading={variables.loadingData}
                  radius="md"
                  type="submit"
                  size="sm"
                  color="zevcore"
                >
                  Update Bank Details
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>
    </div>
  );
}
export default Bank;
