/**

    * Application Name: Imprulife Venture Pvt Ltd Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 03.04.2023
    * Last Modified Date: 03.04.2023
    * Developer Name: Anand_Krishna

**/

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/style.css";
import App from "./App";
import { HashRouter } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import { createBrowserHistory } from "history";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const history = createBrowserHistory({ window });
root.render(
  <HashRouter history={history}>
    <App />
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
